import { CSSProperties, useContext } from 'react'
import { MastData, SignalTypes } from '../../../client'
import MastContext from '../../../contexts/MastContext'
import calcVerticalHeight from '../../../utils/calcVerticalHeight'
import ColorScale, { plot_color_scale_200 } from '../../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../../utils/plotly'
import { sortByDateKey } from '../../../utils/sort'
import Plot from '../../shared/Plot'
import useData from '../useData'

const config = { ...defaultConfig }
const style: CSSProperties = {
  minHeight: '10rem',
  height: calcVerticalHeight({ gridSize: 4, elementSize: 3 }),
  width: '100%',
}

const PlotSignal = ({
  dtIn,
  dtFin,
  signalType,
  revision,
}: {
  dtIn: Date
  dtFin: Date
  signalType: SignalTypes
  revision: number
}) => {
  const { mast, sensors: allSensors } = useContext(MastContext)
  const { mastDataBySensor } = useData(dtIn, dtFin)
  const sensors = allSensors.filter(s => signalType.id === s.signal_type_id)

  const layout = {
    template: layoutTemplate,
    autorange: false,
    yaxis: { range: [signalType.min_graph, signalType.max_graph] },
    xaxis: { range: [dtIn, dtFin] },
  }

  const data = sensors
    .flatMap((s, i) => {
      const _mastData = mastDataBySensor.get(s.id)
      if (!_mastData) {
        return null
      }
      const mastData = sortByDateKey<MastData>(_mastData, 'ts', 'asc')
      const x = mastData.map(d => d.ts)
      const avg: number[] = []
      const lowerBound: number | null = s.min_estimate
      const upperBound: number | null = s.max_estimate
      const avgEstimated: number | null = s.avg_estimate
      const sensorColor: string = plot_color_scale_200[i]

      mastData.forEach(md => {
        avg.push(md.avg)
      })

      if (lowerBound == null || upperBound == null || avgEstimated == null) {
        return [
          {
            x,
            y: avg,
            type: 'scatter',
            mode: 'line',
            name: `${s.label} - AVG`,
            marker: { color: sensorColor },
          },
        ]
      }

      return [
        {
          x,
          y: avg,
          type: 'scatter',
          mode: 'line',
          name: `${s.label} - AVG`,
          marker: { color: sensorColor },
        },
        {
          x: [dtIn, dtFin],
          y: [lowerBound, lowerBound],
          type: 'scatter',
          mode: 'lines',
          marker: { color: ColorScale.vermelho_puro },
          name: 'Min Estimated',
        },
        {
          x: [dtIn, dtFin],
          y: [upperBound, upperBound],
          type: 'scatter',
          mode: 'lines',
          marker: { color: ColorScale.vermelho_puro },
          name: 'Max Estimated',
        },
        {
          x: [dtIn, dtFin],
          y: [avgEstimated, avgEstimated],
          type: 'scatter',
          mode: 'lines',
          marker: { color: ColorScale.cinza_medio_claro },
          name: 'Avg Estimated',
        },
      ]
    })
    .filter(d => d)

  return (
    <div style={style}>
      <Plot
        key={mast.id}
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        revision={revision}
      />
    </div>
  )
}
export default PlotSignal
