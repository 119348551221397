import { WindFarms } from '../../client'
import SelectList from '../shared/selects/DeprecatedSelectList'

const SelectWindFarms = ({
  selectedWindFarm,
  windFarms,
  onChange,
  isLoading,
}: {
  selectedWindFarm: string
  windFarms: WindFarms[]
  onChange: (newVal: any) => void
  isLoading: boolean
}) => {
  return (
    <SelectList
      startSelected={selectedWindFarm}
      onChange={onChange}
      isLoading={!!isLoading}
      list={windFarms.map((w: WindFarms) => {
        return { value: w.wf_id, label: w.wf }
      })}
    />
  )
}
export default SelectWindFarms
