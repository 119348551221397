import { sum } from 'd3-array'
import months from '../../utils/months'
import { defaultAxis, layoutTemplate } from '../../utils/plotly'

import {
  CounterDataTemporalDaily,
  CounterDataTemporalDailyPark,
  CounterDataTemporalDailyTurb,
} from '../../client'
import ColorScale from '../../utils/colorScale'
import { filterYearMonth, toDateTuple, withoutTimeToDate } from '../../utils/formatDate'
import Plot from '../shared/Plot'
import { categories, DEFAULT_PLOT_CONFIG, PLOT_STYLE, PLOT_TEMPORAL_MONTHLY } from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Mês',
    type: 'category',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const PlotMonthlyTotal = ({
  categorySelection,
  isLoading,
  dataIsLoading,
  revision,
  data,
  plotMonths,
  plotMonthsLabels,
  divId,
}: {
  categorySelection: categories
  isLoading: boolean
  dataIsLoading: boolean
  revision: number
  data: CounterDataTemporalDaily[] | CounterDataTemporalDailyPark[] | CounterDataTemporalDailyTurb[]
  plotMonths: number[][]
  plotMonthsLabels: string[]
  divId: string
}) => {
  const _defaultAxis = { ...defaultAxis, x: plotMonthsLabels, type: 'bar', hoverinfo: 'all' }

  // {online: 0, downtime:0}
  const monthlyTotals = new Map<number, { online: number; downtime: number }>()

  data.forEach(dd => {
    const [yyyy, _mm, _] = toDateTuple(withoutTimeToDate(dd.ts))
    const mm = _mm + 1

    const monthTotal = monthlyTotals.get(yyyy * 100 + mm) ?? { online: 0, downtime: 0 }

    const downtime = sum(categorySelection.downtime.map(cat => dd[cat]))
    const online = sum(categorySelection.online.map(cat => dd[cat]))

    monthTotal.online += online
    monthTotal.downtime += downtime

    monthlyTotals.set(yyyy * 100 + mm, monthTotal)
  })

  const y = plotMonths.map(ym => {
    const [yyyy, mm] = ym

    const monthTotal = monthlyTotals.get(yyyy * 100 + mm) ?? { online: 0, downtime: 0 }

    return (monthTotal.online / (monthTotal.online + monthTotal.downtime)) * 100
  })

  const traces = [
    {
      ..._defaultAxis,
      y,
      name: 'Disponibilidade Temporal',
      type: 'bar',
      marker: {
        color: ColorScale.verde,
      },
    },
  ]

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_MONTHLY}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_MONTHLY}
        useResizeHandler
        key={revision}
        data={traces}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotMonthlyTotal
