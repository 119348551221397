import { Divider, Grid, GridItem, Panel, PanelMain, PanelMainBody } from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'
import { Turbines } from '../../client'
import PanelSickHeader from '../../components/PanelStickyHeader'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import { categories, PLOT_TEMPORAL_MONTHLY_TURBS } from './constants'
import PlotDailyTurb from './PlotDailyTurb'
import PlotDailyTurbTotal from './PlotDailyTurbTotal'
import PlotMonthly from './PlotMonthly'
import PlotMonthlyTotal from './PlotMonthlyTotal'
import PlotPeriodWTG from './PlotPeriodWTG'
import PlotPeriodWTGTotal from './PlotPeriodWTGTotal'
import useData from './useData'

const PanelTurb = ({
  dateIn,
  dateFin,
  revision,
  categorySelection,
  activeType,
}: {
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
  activeType: 'Total' | 'Por Categoria'
}) => {
  const { turbs, isLoading: sitesIsLoading } = useContext(SitesContext)
  const [selected, setSelected] = useState(turbs[0])

  const handleArrow = (side: string) => {
    const selectedIndex = turbs.findIndex(e => e.turb_id === selected.turb_id)

    if (side === 'prev') {
      if (selectedIndex === 0) {
        setSelected(turbs[turbs.length])
        return
      }
      setSelected(turbs[selectedIndex - 1])
    } else {
      if (selectedIndex === turbs.length) {
        setSelected(turbs[0])
        return
      }
      setSelected(turbs[selectedIndex + 1])
    }
  }

  useEffect(() => {
    if (sitesIsLoading || turbs.length === 0) return
    setSelected(turbs[0])
  }, [sitesIsLoading, turbs])

  const {
    isLoading: dataIsLoading,
    daily,
    dailyParks,
    dailyTurbs,
    monthly,
    monthlyParks,
    monthlyTurbs,
    plotMonths,
    plotMonthsLabels,
  } = useData(dateIn, dateFin)

  const dailyTurb = dailyTurbs.filter(e => e.turb_id === selected?.turb_id)

  return (
    <Panel className='pf-v5-u-box-shadow-sm'>
      <PanelSickHeader title='Disponibilidade Temporal Por WTG' />
      <PanelMain>
        <PanelMainBody>
          <Grid hasGutter>
            <GridItem lg={12}>
              {activeType === 'Total' ? (
                <PlotPeriodWTGTotal
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotPeriodWTG
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                />
              )}
            </GridItem>
            <GridItem lg={12}>
              <Divider />
            </GridItem>
            <GridItem lg={12}>
              <Select<Turbines>
                items={turbs}
                itemValueName='name'
                itemKeyName='turb_id'
                selected={selected}
                onChange={setSelected}
                onClickNext={() => handleArrow('next')}
                onClickPrev={() => handleArrow('prev')}
                isLoading={sitesIsLoading}
                enableFilter
                maxHeight={250}
              />
            </GridItem>
            <GridItem lg={7}>
              {activeType === 'Total' ? (
                <PlotDailyTurbTotal
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  turbId={selected?.turb_id}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotDailyTurb
                  isLoading={sitesIsLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  turbId={selected?.turb_id}
                  revision={revision}
                />
              )}
            </GridItem>
            <GridItem lg={5}>
              {activeType === 'Total' ? (
                <PlotMonthlyTotal
                  categorySelection={categorySelection}
                  isLoading={sitesIsLoading}
                  dataIsLoading={dataIsLoading}
                  revision={revision}
                  data={dailyTurb}
                  plotMonths={plotMonths}
                  plotMonthsLabels={plotMonthsLabels}
                  divId={PLOT_TEMPORAL_MONTHLY_TURBS}
                />
              ) : (
                <PlotMonthly
                  isLoading={sitesIsLoading}
                  dataIsLoading={dataIsLoading}
                  revision={revision}
                  data={dailyTurb}
                  plotMonths={plotMonths}
                  plotMonthsLabels={plotMonthsLabels}
                  divId={PLOT_TEMPORAL_MONTHLY_TURBS}
                />
              )}
            </GridItem>
          </Grid>
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
}
export default PanelTurb
