/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageUserCachedPermission } from '../models/PageUserCachedPermission';
import type { PermissionTypes } from '../models/PermissionTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PermissionsAPI {

  /**
   * Get All
   * @returns PageUserCachedPermission Successful Response
   * @throws ApiError
   */
  public static getAll(): CancelablePromise<Array<PageUserCachedPermission>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/permissions',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Get Types
   * @returns PermissionTypes Successful Response
   * @throws ApiError
   */
  public static getTypes(): CancelablePromise<Array<PermissionTypes>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/permission_types',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

}
