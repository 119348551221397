import { CSSProperties, useMemo } from 'react'
import { AvailabilityTimeSeries, DowntimeAero, DowntimeBop, DowntimeONS } from '../../client'
import ColorScale from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

interface IPower {
  pwr: number
  pwr_exp: number
}

const PlotExpectedEnergy = ({
  downtimeAero,
  downtimeBop,
  downtimeOns,
  timeseries,
  revision,
  style,
}: {
  downtimeAero: DowntimeAero[]
  downtimeBop: DowntimeBop[]
  downtimeOns: DowntimeONS[]
  timeseries: AvailabilityTimeSeries[]
  revision: number
  style: CSSProperties
}) => {
  const layout = {
    template: layoutTemplate,
    margin: { b: 15, t: 10, l: 50, r: 35 },
    xaxis: {
      type: 'datetime',
      linewidth: 2,
      gridwidth: 2,
      domain: [0.045, 1],
      mirror: 'ticks',
      zeroline: false,
      rangeslider: { range: undefined },
      rangeselector: {
        buttons: [
          {
            count: 1,
            label: '24h',
            step: 'day',
            stepmode: 'forward',
          },
          {
            count: 2,
            label: '48h',
            step: 'day',
            stepmode: 'forward',
          },
        ],
      },
    },
    yaxis: {
      title: 'Potência (kW)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
    },
    yaxis2: {
      title: 'Velocidade do Vento (m/s)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      side: 'left',
      overlaying: 'y',
      type: 'linear',
      anchor: 'free',
      autoshift: true,
    },
    yaxis3: {
      automargin: true,
      type: 'category',
      overlaying: 'y',
      side: 'right',
      autoshift: true,
      categoryorder: 'array',
      categoryarray: ['BOP', 'AERO', 'ONS'],
      autorange: false,
      range: [-0.5, 2.5],
    },
  }

  const config = {
    ...defaultConfig,
    displayModeBar: true,
  }

  const traces = useMemo(() => {
    const opts = {
      mode: 'lines',
      type: 'scatter',
    }
    const downtimeTracesOpts = {
      yaxis: 'y3',
      orientation: 'h',
      hoverinfo: 'skip',
      mode: 'dots',
      showlegend: false,
    }

    const energyExpectedData = timeseries.sort((a, b) => (new Date(a.ts) > new Date(b.ts) ? 1 : -1))
    const x = energyExpectedData.map(ee => ee.ts)

    const bellowExp: IPower[] = energyExpectedData.map(t =>
      t.pwr !== null && t.pwr_exp !== null && t.pwr <= t.pwr_exp
        ? { pwr: t.pwr, pwr_exp: t.pwr_exp }
        : { pwr: 0, pwr_exp: 0 }
    )

    const bellowExpReverse = bellowExp.slice().reverse()

    const pwrHatchValues = bellowExp.map(d => d.pwr)
    const pwrExpHatchValues = bellowExpReverse.map(d => d.pwr_exp)

    const valueLoop = pwrHatchValues.concat(pwrExpHatchValues)
    const tsLoop = x.concat(x.slice().reverse())

    const downtimeBopTrace = downtimeBop.map(d => {
      return {
        x: [d.ts_in, d.ts_fin],
        y: ['BOP', 'BOP'],
        name: d.descricao,
        marker: {
          color: ColorScale.verde,
        },
        ...downtimeTracesOpts,
      }
    })

    const downtimeAeroTrace = downtimeAero.map(d => {
      return {
        x: [d.ts_in, d.ts_fin],
        y: ['Aero', 'Aero'],
        name: d.descricao,
        marker: {
          color: ColorScale.azul_escuro,
        },
        ...downtimeTracesOpts,
      }
    })

    const downtimeOnsTrace = downtimeOns.map(d => {
      return {
        x: [d.ts_in, d.ts_fin],
        y: ['ONS', 'ONS'],
        name: d.motivo,
        marker: {
          color: ColorScale.laranja_international,
        },
        ...downtimeTracesOpts,
      }
    })

    return [
      {
        x,
        y: energyExpectedData.map(ee => ee.nws_pwr_exp),
        name: 'Potência Esperada Nacelle',
        marker: { color: ColorScale.laranja_amarelo },
        mode: 'lines',
      },
      {
        x,
        y: energyExpectedData.map(ee => ee.pwr_exp),
        name: 'Potência Esperada',
        marker: { color: ColorScale.azul_escuro },
        ...opts,
      },
      {
        x,
        y: energyExpectedData.map(ee => ee.pwr),
        name: 'Potência Real',
        marker: { color: ColorScale.verde_cadmium },
        mode: 'lines',
        line: {
          dash: 'dashdot',
          width: 4,
        },
      },
      {
        x,
        y: energyExpectedData.map(ee => ee.nws?.toFixed(1)),
        name: 'Velocidade do Vento Nacelle',
        yaxis: 'y2',
        mode: 'lines',
        marker: { color: ColorScale.cinza_medio_claro },
      },
      {
        x: tsLoop,
        y: valueLoop,
        name: 'Perda de Energia',
        fill: 'toself',
        fillpattern: {
          shape: '',
          size: 5,
        },
        fillcolor: ColorScale.vermelho_puro,
        opacity: 0.375,
        line: { color: ColorScale.transparent_bg },
      },
      ...downtimeAeroTrace,
      ...downtimeBopTrace,
      ...downtimeOnsTrace,
    ]
  }, [timeseries, downtimeAero, downtimeBop, downtimeOns])

  return (
    <div
      style={{ backgroundColor: ColorScale.branco }}
      className='pf-v5-u-p-sm pf-v5-u-box-shadow-sm'
    >
      <Plot
        useResizeHandler
        key={revision}
        data={traces}
        layout={layout}
        config={config}
        style={style}
      />
    </div>
  )
}

export default PlotExpectedEnergy
