import { range } from 'd3-array'
import { A7ProductionDaily } from '../../client'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

const filterMonth = (dailyData: A7ProductionDaily[], year: number, month: number) => {
  return dailyData
    .filter((d: A7ProductionDaily) => d.yyyy === year && d.mm === month)
    .sort((a: A7ProductionDaily, b: A7ProductionDaily) => a.dd - b.dd)
}

const config = {
  ...defaultConfig,
  displayModeBar: false,
}

const layout = {
  template: layoutTemplate,
  barmode: 'relative',
  margin: { b: 35, t: 25, l: 50, r: 5 },
  yaxis: {
    title: 'Produção diária [GWh]',
    gridcolor: COLORS.transparent_bg,
    fixedrange: true,
    linewidth: 2,
    gridwidth: 2,
  },
  xaxis: {
    title: 'Dia',
    type: 'category',
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
  },
  xaxis2: {
    type: 'category',
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    gridcolor: COLORS.transparent_bg,
    overlaying: 'x',
  },
  plot_bgcolor: COLORS.transparent_bg,
}

const ChartProductionDaily = ({
  dailyData,
  year,
  month,
  revision,
  plotStyle,
  isLoading,
}: {
  dailyData: A7ProductionDaily[]
  year: number
  month: number
  revision: number
  plotStyle: any
  isLoading: boolean
}) => {
  if (isLoading) return null

  const dtNow = new Date()
  const yyyymmdd = dtNow.getFullYear() * 10000 + (dtNow.getMonth() + 1) * 100 + dtNow.getDate()

  const style = { ...plotStyle }
  const data = [
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      name: 'Realizado',
      type: 'bar',
      marker: { color: COLORS.verde },
      hoverinfo: 'all',
      base: null,
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      name: 'Meta compensada',
      type: 'bar',
      xaxis: 'x2',
      marker: { color: COLORS.azul_pantone, opacity: 0.4 },
      hoverinfo: 'all',
      base: null,
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      name: 'P50 Diário',
      type: 'scatter',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.azul_escuro },
      hoverinfo: 'all',
      base: null,
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'BOP Restrição ONS',
      type: 'bar',
      marker: { color: COLORS.laranja_international },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'BOP Parada',
      type: 'bar',
      marker: { color: COLORS.amarelo_pantone },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'Turbina Parada',
      type: 'bar',
      marker: { color: COLORS.roxo_indigo },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: Array<number | undefined>(),
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'Windness',
      type: 'bar',
      marker: {
        pattern: {
          shape: '/',
          bgcolor: COLORS.cinza_medio_claro,
          solidity: 0.4,
        },
        color: 'white',
      },
      hoverinfo: 'all',
    },
  ]
  filterMonth(dailyData, year, month).forEach((e: A7ProductionDaily) => {
    const actual = e.actual ? e.actual / 1000 : 0
    const meta_compensada = e.meta_compensada ? e.meta_compensada / 1000 : 0
    const p50 = e.p50 ? e.p50 / 1000 : 0
    const bop_parada = e.bop_parada ? e.bop_parada / 1000 : 0
    const bop_restric = e.bop_restric ? e.bop_restric / 1000 : 0
    const turbine_parada = e.turbine_parada ? e.turbine_parada / 1000 : 0
    let windness = p50 - (actual + bop_parada + bop_restric + turbine_parada)
    windness = windness > 0 ? windness : 0
    windness = actual < p50 ? windness : 0
    windness = e.actual ? windness : 0
    windness = year >= 2025 ? windness : 0

    const _yyyymmdd = e.yyyy * 10000 + e.mm * 100 + e.dd

    windness = _yyyymmdd >= yyyymmdd ? 0 : windness

    data[0].x.push(e.dd)
    data[0].y.push(actual)

    data[1].x.push(e.dd)
    data[1].y.push(meta_compensada)

    data[2].x.push(e.dd)
    data[2].y.push(p50)

    data[3].x.push(e.dd)
    data[3].y.push(bop_restric)
    data[3].base?.push(actual)

    data[4].x.push(e.dd)
    data[4].y.push(bop_parada)
    data[4].base?.push(actual + bop_restric)

    data[5].x.push(e.dd)
    data[5].y.push(turbine_parada)
    data[5].base?.push(actual + bop_restric + bop_parada)

    data[6].x.push(e.dd)
    data[6].y.push(windness)
    data[6].base?.push(actual + bop_restric + bop_parada + turbine_parada)
  })

  return (
    <div className='pf-v5-u-p-sm-on-sm'>
      <Plot
        divId='ChartProductionDaily'
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        key={revision}
      />
    </div>
  )
}

export default ChartProductionDaily
