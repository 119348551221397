/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { A7ProductionDaily } from '../models/A7ProductionDaily';
import type { A7ProductionMonthly } from '../models/A7ProductionMonthly';
import type { A7ProductionMtd } from '../models/A7ProductionMtd';
import type { A7ProductionYtd } from '../models/A7ProductionYtd';
import type { Bopdata } from '../models/Bopdata';
import type { OldProduction } from '../models/OldProduction';
import type { OldProductionDaily } from '../models/OldProductionDaily';
import type { OldProductionMtd } from '../models/OldProductionMtd';
import type { OldProductionYtd } from '../models/OldProductionYtd';
import type { ProductionBOPDataSignals } from '../models/ProductionBOPDataSignals';
import type { ProductionRealtimePark } from '../models/ProductionRealtimePark';
import type { ProductionRealtimeTurb } from '../models/ProductionRealtimeTurb';
import type { ProductionRealtimeTurbList } from '../models/ProductionRealtimeTurbList';
import type { ProductionTurbDataSignals } from '../models/ProductionTurbDataSignals';
import type { Turbdata } from '../models/Turbdata';
import type { TurbdataWeekYear } from '../models/TurbdataWeekYear';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductionAPI {

  /**
   * Get Monthly
   * @returns A7ProductionMonthly Successful Response
   * @throws ApiError
   */
  public static getMonthly({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<A7ProductionMonthly>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/monthly',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Month To Date
   * @returns A7ProductionMtd Successful Response
   * @throws ApiError
   */
  public static getMonthToDate({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<A7ProductionMtd>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/month-to-date',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Year To Date
   * @returns A7ProductionYtd Successful Response
   * @throws ApiError
   */
  public static getYearToDate({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<A7ProductionYtd>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/year-to-date',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Daily
   * @returns A7ProductionDaily Successful Response
   * @throws ApiError
   */
  public static getDaily({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<A7ProductionDaily>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/daily',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Old Monthly
   * @returns OldProduction Successful Response
   * @throws ApiError
   */
  public static getOldMonthly({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<OldProduction>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/old-monthly',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Old Month To Date
   * @returns OldProductionMtd Successful Response
   * @throws ApiError
   */
  public static getOldMonthToDate({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<OldProductionMtd>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/old-month-to-date',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Old Year To Date
   * @returns OldProductionYtd Successful Response
   * @throws ApiError
   */
  public static getOldYearToDate({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<OldProductionYtd>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/old-year-to-date',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Old Daily
   * @returns OldProductionDaily Successful Response
   * @throws ApiError
   */
  public static getOldDaily({
    siteId,
    wfId,
  }: {
    siteId: number,
    wfId?: number,
  }): CancelablePromise<Array<OldProductionDaily>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/old-daily',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Realtime Park
   * @returns ProductionRealtimePark Successful Response
   * @throws ApiError
   */
  public static getRealtimePark({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<ProductionRealtimePark> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/real-time',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Turbine Production Real Time
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createTurbineProductionRealTime({
    siteId,
    requestBody,
  }: {
    siteId: number,
    requestBody: ProductionRealtimeTurbList,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sites/{site_id}/production/real-time',
      path: {
        'site_id': siteId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        409: `Conflict`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Get Realtime Turb
   * @returns ProductionRealtimeTurb Successful Response
   * @throws ApiError
   */
  public static getRealtimeTurb({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<ProductionRealtimeTurb>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/real-time/turbine',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbdata
   * @returns Turbdata Successful Response
   * @throws ApiError
   */
  public static getTurbdata({
    siteId,
    signalId,
    turbId,
    tsIn,
    tsFin,
    deltaDays,
  }: {
    siteId: number,
    signalId?: (Array<number> | null),
    turbId?: (Array<number> | null),
    tsIn?: (string | null),
    tsFin?: (string | null),
    deltaDays?: (number | null),
  }): CancelablePromise<Array<Turbdata>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/turbdata',
      path: {
        'site_id': siteId,
      },
      query: {
        'signal_id': signalId,
        'turb_id': turbId,
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbdata Week
   * @returns TurbdataWeekYear Successful Response
   * @throws ApiError
   */
  public static getTurbdataWeek({
    siteId,
    year,
    signalId,
    turbId,
  }: {
    siteId: number,
    year: number,
    signalId?: (Array<number> | null),
    turbId?: (Array<number> | null),
  }): CancelablePromise<Array<TurbdataWeekYear>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/turbdata/week',
      path: {
        'site_id': siteId,
      },
      query: {
        'year': year,
        'signal_id': signalId,
        'turb_id': turbId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Turbdata Year
   * @returns TurbdataWeekYear Successful Response
   * @throws ApiError
   */
  public static getTurbdataYear({
    siteId,
    year,
    signalId,
    turbId,
  }: {
    siteId: number,
    year: number,
    signalId?: (Array<number> | null),
    turbId?: (Array<number> | null),
  }): CancelablePromise<Array<TurbdataWeekYear>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/turbdata/year',
      path: {
        'site_id': siteId,
      },
      query: {
        'year': year,
        'signal_id': signalId,
        'turb_id': turbId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Bopdata
   * @returns Bopdata Successful Response
   * @throws ApiError
   */
  public static getBopdata({
    siteId,
    signalId,
    tsIn,
    tsFin,
    deltaDays,
  }: {
    siteId: number,
    signalId?: (Array<number> | null),
    tsIn?: (string | null),
    tsFin?: (string | null),
    deltaDays?: (number | null),
  }): CancelablePromise<Bopdata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/bopdata',
      path: {
        'site_id': siteId,
      },
      query: {
        'signal_id': signalId,
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Turbdata Signals
   * @returns ProductionTurbDataSignals Successful Response
   * @throws ApiError
   */
  public static getAllTurbdataSignals(): CancelablePromise<Array<ProductionTurbDataSignals>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/production/turbdata/signals',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Turbdata Signals
   * @returns ProductionTurbDataSignals Successful Response
   * @throws ApiError
   */
  public static getTurbdataSignals({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<ProductionTurbDataSignals>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/turbdata/signals',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get All Bopdata Signals
   * @returns ProductionBOPDataSignals Successful Response
   * @throws ApiError
   */
  public static getAllBopdataSignals(): CancelablePromise<Array<ProductionBOPDataSignals>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/production/bopdata/signals',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Get Bopdata Signals
   * @returns ProductionBOPDataSignals Successful Response
   * @throws ApiError
   */
  public static getBopdataSignals({
    siteId,
  }: {
    siteId: number,
  }): CancelablePromise<Array<ProductionBOPDataSignals>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/bopdata/signals',
      path: {
        'site_id': siteId,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Production Mtd Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getProductionMtdReport({
    siteId,
    wfId,
    mm,
    yyyy,
  }: {
    siteId: number,
    wfId?: number,
    mm?: (number | null),
    yyyy?: number,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'wf_id': wfId,
        'mm': mm,
        'yyyy': yyyy,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Production Turbdata Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getProductionTurbdataReport({
    siteId,
    signalId,
    turbId,
    tsIn,
    tsFin,
    deltaDays,
  }: {
    siteId: number,
    signalId?: (Array<number> | null),
    turbId?: (Array<number> | null),
    tsIn?: (string | null),
    tsFin?: (string | null),
    deltaDays?: (number | null),
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/sites/{site_id}/production/turbdata/report',
      path: {
        'site_id': siteId,
      },
      query: {
        'signal_id': signalId,
        'turb_id': turbId,
        'ts_in': tsIn,
        'ts_fin': tsFin,
        'delta_days': deltaDays,
      },
      errors: {
        401: `Unauthorized`,
        422: `Validation Error`,
      },
    });
  }

}
