import { Bullseye, Spinner } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { CSSProperties, useContext, useMemo } from 'react'
import { Alarms, AvailabilityAPI } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import { QUERY_OPTIONS } from '../../services/api'
import ColorScale from '../../utils/colorScale'
import { toISOString } from '../../utils/formatDate'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import { QUERY_KEY_EXP_PWR } from './constants'
import { IEvent } from './TableAero'

const CONFIG = { ...defaultConfig, displayModeBar: true }
const HOUR = 1000 * 60 * 60

const get_timeseries = ({
  siteId,
  turbId,
  startDate,
  endDate,
}: {
  siteId: number
  turbId: number | null
  startDate: Date
  endDate: Date
}) => {
  if (!turbId) return []
  return AvailabilityAPI.getTimeseries({
    siteId,
    dateIn: toISOString(startDate),
    dateFin: toISOString(endDate),
    turbIds: [turbId],
  })
}

const PlotExpectedEnergy = ({
  event,
  tsIn,
  tsFin,
  setTsIn,
  setTsFin,
  style,
  alarms,
}: {
  style: CSSProperties
  event: IEvent
  tsIn: Date
  tsFin: Date
  setTsIn: (ts: Date) => void
  setTsFin: (ts: Date) => void
  alarms?: Alarms[]
}) => {
  const greaterThen24h = new Date(tsFin).getTime() - new Date(tsIn).getTime() < HOUR * 24
  const plotFullRange = greaterThen24h
    ? [new Date(tsIn.getTime() - HOUR * 3), new Date(tsFin.getTime() + HOUR * 3)]
    : [new Date(tsIn.getTime() - HOUR * 12), new Date(tsFin.getTime() + HOUR * 12)]
  const [plotStart, plotEnd] = plotFullRange

  const layout = {
    template: layoutTemplate,
    margin: { b: 45, t: 15, l: 50, r: 35 },
    barmode: 'stack',
    xaxis: {
      type: 'datetime',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      range: plotFullRange,
      rangeslider: { range: undefined },
    },
    yaxis: {
      title: 'Potência (kW)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      automargin: true,
      fixedrange: true,
    },
    yaxis2: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      side: 'right',
      overlaying: 'y',
      type: 'category',
      automargin: true,
      fixedrange: true,
    },
    shapes: [
      {
        type: 'rect',
        x0: tsIn,
        x1: tsFin,
        y0: 0,
        yref: 'paper',
        xref: 'x',
        y1: 1,
        fillcolor: '#d3d3d3',
        opacity: 0.3,
        line: {
          width: 0,
        },
      },
    ],
  }
  const { site, isLoading: sitesIsLoading } = useContext(SitesContext)
  const queryOptions = { ...QUERY_OPTIONS, enabled: !!site?.site_id }

  const queryKey = [
    site.site_id,
    event.turb_id,
    JSON.stringify({ startDate: tsIn, endDate: tsFin }),
  ]

  const { isLoading: timeseriesIsLoading, data: timeseries } = useQuery(
    [QUERY_KEY_EXP_PWR, ...queryKey],
    async () =>
      get_timeseries({
        siteId: site?.site_id,
        turbId: event?.turb_id,
        startDate: plotStart,
        endDate: plotEnd,
      }),
    queryOptions
  )

  const pwr_traces = useMemo(() => {
    if (!timeseries) return []
    const data = timeseries.sort((a, b) => (new Date(a.ts) > new Date(b.ts) ? 1 : -1))
    const x = data.map(ee => ee.ts)
    const opts = { x, mode: 'lines', type: 'scatter' }
    return [
      {
        y: data.map(ee => ee.pwr_exp),
        name: 'Potência Esperada',
        marker: { color: ColorScale.azul_escuro },
        line: { dash: 'dashdot', width: 4 },
        ...opts,
      },
      {
        y: data.map(ee => ee.pwr),
        name: 'Potência Real',
        marker: { color: ColorScale.verde },
        ...opts,
      },
      {
        y: data.map(ee => ee.nws_pwr_exp),
        name: 'Potência Esperada Nacelle',
        marker: { color: ColorScale.laranja_amarelo },
        ...opts,
      },
    ]
  }, [timeseries])

  const uniqueAlarmList = useMemo(() => {
    if (!alarms) return []
    return [...new Set(alarms.map(e => e.error))]
  }, [alarms])

  const alarmTraces = uniqueAlarmList
    .map(u => {
      const _alarms = alarms ?? []
      const alarms_filtered = _alarms
        .filter(
          a =>
            a.error === u &&
            !!a.dur_min &&
            !!a.ts_in &&
            a.ts_in &&
            new Date(a.ts_in) >= tsIn &&
            new Date(a.ts_fin) <= tsFin &&
            event.turb_id === a.turb_id
        )
        .map(a => {
          return {
            x: [new Date(a.ts_fin).getTime() - new Date(a.ts_in).getTime()],
            y: [a.error],
            base: [new Date(a.ts_in).getTime()],
            width: 0.1,
            name: a.description,
            orientation: 'h',
            hoverinfo: 'text',
            text: `${a.error} - ${a.description}`,
            type: 'bar',
            yaxis: 'y2',
            showlegend: false,
          }
        })

      return alarms_filtered
    })
    .flat()

  const isLoading = sitesIsLoading || timeseriesIsLoading

  const handleRelayout = (event: any) => {
    // Do not to be confused with xaxis.range.
    // Slider re-layout event prop is 'xaxis.range: Array(2)' while manual zoom is 'xaxis.range[0]' and 'xaxis.range[1]'
    if (!Object.hasOwn(event, 'xaxis.range[0]') || !Object.hasOwn(event, 'xaxis.range[1]')) {
      // console.log('Skipping re-layout')
      // Slider action should not trigger time range change
      return
    }
    const tsIn = new Date(event['xaxis.range[0]'])
    const tsFin = new Date(event['xaxis.range[1]'])
    setTsIn(tsIn)
    setTsFin(tsFin)
  }

  return (
    <div className='pf-v5-u-p-sm'>
      {isLoading ? (
        <Bullseye style={style}>
          <div>
            <Spinner />
          </div>
        </Bullseye>
      ) : (
        <Plot
          useResizeHandler
          data={[...pwr_traces, ...(alarmTraces ?? [])]}
          layout={layout}
          config={CONFIG}
          style={style}
          onRelayout={handleRelayout}
        />
      )}
    </div>
  )
}

export default PlotExpectedEnergy
