import { CSSProperties, useContext, useMemo } from 'react'
import { EnergyExpectedSite } from '../../client'
import SitesContext from '../../contexts/SitesContext'
import ColorScale from '../../utils/colorScale'
import { defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

interface IPower {
  pwr: number
  pwr_exp: number
}

const floorToNearestMultipleOf10 = (data: Date) => {
  const minutes = data.getMinutes()
  const minutesFloor = Math.floor(minutes / 10) * 10

  const dateTreatment = new Date(data)
  dateTreatment.setMinutes(minutesFloor)

  return dateTreatment
}

const createRange = ({
  expectedEnergySite,
  tsIn,
  tsFin,
}: {
  expectedEnergySite: EnergyExpectedSite[]
  tsIn?: Date
  tsFin?: Date
}) => {
  if (tsIn && tsFin) {
    const hoursInterval =
      tsFin.getTime() - tsIn.getTime() < 1000 * 60 * 60 * 24 ? 1000 * 60 * 60 : 1000 * 60 * 60 * 12

    const findIndexTsIn: number = expectedEnergySite.findIndex(
      item =>
        new Date(item.ts).getTime() <= floorToNearestMultipleOf10(tsIn).getTime() - hoursInterval &&
        new Date(item.ts).getTime() >= floorToNearestMultipleOf10(tsIn).getTime() - hoursInterval
    )

    const findIndexTsFin: number = expectedEnergySite.findIndex(
      item =>
        new Date(item.ts).getTime() <=
          floorToNearestMultipleOf10(tsFin).getTime() + hoursInterval &&
        new Date(item.ts).getTime() >= floorToNearestMultipleOf10(tsFin).getTime() + hoursInterval
    )

    const initialIndex = expectedEnergySite[findIndexTsIn] ? findIndexTsIn : 0
    const finalIndex = expectedEnergySite[findIndexTsFin]
      ? findIndexTsFin
      : expectedEnergySite.length - 1

    return [
      new Date(expectedEnergySite[initialIndex]?.ts),
      new Date(expectedEnergySite[finalIndex]?.ts),
    ]
  }
}

const PlotExpectedEnergy = ({
  expectedEnergySite,
  revision,
  style,
  tsIn,
  tsFin,
}: {
  expectedEnergySite: EnergyExpectedSite[]
  revision: number
  style: CSSProperties
  tsIn?: Date
  tsFin?: Date
}) => {
  const { turbs } = useContext(SitesContext)

  const layout = {
    template: layoutTemplate,
    xaxis: {
      type: 'datetime',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      ...(tsIn &&
        tsFin && {
          range: createRange({ tsIn, tsFin, expectedEnergySite }),
        }),
    },
    yaxis: {
      title: 'Potência (MW)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      fixedrange: true,
    },
    yaxis2: {
      title: 'Velocidade do Vento (m/s)',
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      overlaying: 'y',
      side: 'right',
      zeroline: false,
      fixedrange: true,
    },
  }

  const config = {
    ...defaultConfig,
  }

  const traces = useMemo(() => {
    const data = expectedEnergySite.sort((a, b) => (new Date(a.ts) > new Date(b.ts) ? 1 : -1))

    const x = data.map(ee => ee.ts)
    const yPwrExp = data.map(ee => ee.pwr_exp / 1000)
    const yPwr = data.map(ee => ee.pwr / 1000)
    const yNwsPwrExp = data.map(ee => ee.nws_pwr_exp / 1000)
    const yNwsMean = data.map(ee => ee.nws / turbs.length)
    const yEneatDel = data.map(ee => ee.pwr_bop / 1000)

    const bellowExp: IPower[] = data.map(ee =>
      ee.pwr < ee.pwr_exp ? { pwr: ee.pwr, pwr_exp: ee.pwr_exp } : { pwr_exp: 0, pwr: 0 }
    )
    const bellowExpReverse = bellowExp.slice().reverse()

    const pwrHatchValues = bellowExp.map(d => d.pwr / 1000)
    const pwrExpHatchValues = bellowExpReverse.map(d => d.pwr_exp / 1000)

    const valueLoop = pwrHatchValues.concat(pwrExpHatchValues)
    const tsLoop = x.concat(x.slice().reverse())

    return [
      {
        x,
        y: yNwsPwrExp,
        name: 'Potência Esperada Nacelle Complexo',
        marker: { color: ColorScale.laranja_amarelo },
        mode: 'lines',
      },
      {
        x,
        y: yPwrExp,
        name: 'Potência Esperada Complexo',
        marker: { color: ColorScale.azul_escuro },
        mode: 'lines',
        type: 'scatter',
        line: { dash: 'dashdot' },
      },
      {
        x,
        y: yPwr,
        name: 'Potência Real Complexo',
        marker: { color: ColorScale.verde_cadmium },
        mode: 'lines',
      },
      {
        x,
        y: yEneatDel,
        name: 'BOP [EneatDel]',
        marker: { color: ColorScale.verde },
        mode: 'lines',
      },
      {
        x,
        y: yNwsMean,
        name: 'Velocidade do Vento Nacelle Complexo',
        marker: { color: ColorScale.cinza_medio_claro },
        mode: 'lines',
        yaxis: 'y2',
      },
      {
        x: tsLoop,
        y: valueLoop,
        name: 'Perda de Energia',
        fill: 'toself',
        fillpattern: {
          shape: '',
          size: 5,
        },
        fillcolor: ColorScale.vermelho_puro,
        opacity: 0.375,
        line: { color: ColorScale.transparent_bg },
      },
    ]
  }, [expectedEnergySite])

  return (
    <div className='pf-v5-u-p-sm'>
      <Plot
        useResizeHandler
        key={revision}
        data={traces}
        layout={layout}
        config={config}
        style={style}
      />
    </div>
  )
}

export default PlotExpectedEnergy
