import { useQuery } from '@tanstack/react-query'
import { createContext, ReactNode, useEffect } from 'react'
import {
  GroupOut,
  GroupsAPI,
  Me,
  Pages,
  PagesAPI,
  PermissionsAPI,
  PermissionTypes,
  UserOut,
  UsersAPI,
} from '../client'

import { AxiosError } from 'axios'
import { getToken, logout } from '../services/auth'

const queryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

interface IPermissionContext {
  users: UserOut[]
  groups: GroupOut[]
  pages: Pages[]
  permissionTypes: PermissionTypes[]
  me: Me
  meIsLoading: boolean
  isLoading: boolean
}

const PermissionContextDefaults: IPermissionContext = {
  users: new Array<UserOut>(),
  groups: new Array<GroupOut>(),
  pages: new Array<Pages>(),
  permissionTypes: new Array<PermissionTypes>(),
  me: {} as Me,
  meIsLoading: true,
  isLoading: true,
}

export const QUERY_KEY_ME = 'me'
export const QUERY_KEY_PAGES = 'pages'
export const QUERY_KEY_USERS = 'users'
export const QUERY_KEY_GROUPS = 'groups'
export const QUERY_KEY_PERMISSION_TYPES = 'permission_types'

const PermissionContext = createContext<IPermissionContext>(PermissionContextDefaults)

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const token = getToken()

  const {
    isLoading: meIsLoading,
    data: me,
    error: errorMe,
  } = useQuery({
    queryKey: [QUERY_KEY_ME],
    queryFn: UsersAPI.getMe,
    enabled: !!token,
    ...queryOptions,
  })

  useEffect(() => {
    const error = errorMe as AxiosError
    if (error && error?.response?.status === 401) logout()
  }, [errorMe])

  const { isLoading: pagesIsLoading, data: pages } = useQuery({
    queryKey: [QUERY_KEY_PAGES],
    queryFn: PagesAPI.getAll,
    enabled: !!me,
    ...queryOptions,
  })

  const { isLoading: usersIsLoading, data: users } = useQuery({
    queryKey: [QUERY_KEY_USERS],
    queryFn: UsersAPI.getAll,
    enabled: !!me,
    ...queryOptions,
  })

  const { isLoading: groupsIsLoading, data: groups } = useQuery({
    queryKey: [QUERY_KEY_GROUPS],
    queryFn: GroupsAPI.getAll,
    enabled: !!me,
    ...queryOptions,
  })

  const { isLoading: permTypesIsLoading, data: permissionTypes } = useQuery({
    queryKey: [QUERY_KEY_PERMISSION_TYPES],
    queryFn: PermissionsAPI.getTypes,
    enabled: !!me,
    ...queryOptions,
  })

  const isLoading =
    meIsLoading || pagesIsLoading || usersIsLoading || groupsIsLoading || permTypesIsLoading

  const provides: IPermissionContext = {
    users: users || [],
    groups: groups || [],
    pages: pages || [],
    permissionTypes: permissionTypes || [],
    me: me || ({} as Me),
    meIsLoading,
    isLoading,
  }

  return <PermissionContext.Provider value={provides}>{children}</PermissionContext.Provider>
}

export default PermissionContext
