import { useQuery } from '@tanstack/react-query'
import { range } from 'd3-array'
import { useContext, useEffect } from 'react'
import {
  AvailabilityAPI,
  CounterDataTemporalDaily,
  CounterDataTemporalDailyPark,
  CounterDataTemporalDailyTurb,
  CounterDataTemporalMonthly,
  CounterDataTemporalMonthlyPark,
  CounterDataTemporalMonthlyTurb,
} from '../../client'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import months from '../../utils/months'

const CACHE_KEY_DAILY = 'disponibilidade-temporal-daily'
const CACHE_KEY_MONTHLY = 'disponibilidade-temporal-monthly'

type dailyDefault = [
  CounterDataTemporalDaily[],
  CounterDataTemporalDailyPark[],
  CounterDataTemporalDailyTurb[]
]
type monthlyDefault = [
  CounterDataTemporalMonthly[],
  CounterDataTemporalMonthlyPark[],
  CounterDataTemporalMonthlyTurb[]
]

const DEFAULT_DATA = {
  daily: [] as CounterDataTemporalDaily[],
  dailyTurb: [] as CounterDataTemporalDailyTurb[],
  dailyPark: [] as CounterDataTemporalDailyPark[],
  monthly: [] as CounterDataTemporalMonthly[],
  monthlyTurb: [] as CounterDataTemporalMonthlyTurb[],
  monthlyPark: [] as CounterDataTemporalMonthlyPark[],
  dailyDefault: [[], [], []] as dailyDefault,
  monthlyDefault: [[], [], []] as monthlyDefault,
}

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const formatDate = (date?: Date) => date?.toISOString()?.split('T')[0]

const getDaily = async (siteId: number, dateIn: Date, dateFin: Date): Promise<dailyDefault> => {
  const args = { siteId, dateIn: formatDate(dateIn), dateFin: formatDate(dateFin) }

  const [daily, dailyParks, dailyTurbs] = await Promise.all([
    AvailabilityAPI.getCountersDaily(args),
    AvailabilityAPI.getCountersDailyParks(args),
    AvailabilityAPI.getCountersDailyTurbs(args),
  ])
  return [daily, dailyParks, dailyTurbs]
}

const prefetchDaily = async (siteId: number, dateIn: Date, dateFin: Date) => {
  await queryClient.prefetchQuery(
    [CACHE_KEY_DAILY, siteId, formatDate(dateIn), formatDate(dateFin)],
    async () => getDaily(siteId, dateIn, dateFin),
    defaultQueryOptions
  )
}

const getMonthly = async (siteId: number): Promise<monthlyDefault> => {
  const [monthly, monthlyParks, monthlyTurbs] = await Promise.all([
    AvailabilityAPI.getCountersMonthly({ siteId }),
    AvailabilityAPI.getCountersMonthlyParks({ siteId }),
    AvailabilityAPI.getCountersMonthlyTurbs({ siteId }),
  ])
  return [monthly, monthlyParks, monthlyTurbs]
}

const prefetchMonthly = async (siteId: number) => {
  await queryClient.prefetchQuery(
    [CACHE_KEY_MONTHLY, siteId],
    async () => getMonthly(siteId),
    defaultQueryOptions
  )
}

const useData = (dateIn: Date, dateFin: Date) => {
  const { site, sites } = useContext(SitesContext)
  const enabled = !!site?.site_id
  const siteId = site?.site_id ?? 0

  const { isLoading: dailyIsLoading, data: dailyData } = useQuery(
    [CACHE_KEY_DAILY, siteId, formatDate(dateIn), formatDate(dateFin)],
    async () => getDaily(siteId, dateIn, dateFin),
    { ...defaultQueryOptions, enabled }
  )
  const { isLoading: monthlyIsLoading, data: monthlyData } = useQuery(
    [CACHE_KEY_MONTHLY, siteId],
    async () => getMonthly(siteId),
    { ...defaultQueryOptions, enabled }
  )

  useEffect(() => {
    if (dailyIsLoading || monthlyIsLoading) return
    sites.filter(s => s.site_id !== siteId).forEach(s => prefetchMonthly(s.site_id))
    sites.filter(s => s.site_id !== siteId).forEach(s => prefetchDaily(s.site_id, dateIn, dateFin))
  }, [dailyIsLoading, monthlyIsLoading, dateIn, dateFin])

  const [daily, dailyParks, dailyTurbs] = dailyData ?? DEFAULT_DATA.dailyDefault
  const [monthly, monthlyParks, monthlyTurbs] = monthlyData ?? DEFAULT_DATA.monthlyDefault

  const years = range(dateIn.getFullYear(), dateFin.getFullYear() + 1)

  const plotMonths =
    years.length === 1
      ? years.flatMap(y => range(1, 13).map(m => [y, m]))
      : years
          .flatMap(y => range(1, 13).map(m => [y, m]))
          .filter(ym => {
            const [yyyy, mm] = ym

            const yyyymm = yyyy * 100 + mm
            const dtInYYYYMM = dateIn.getUTCFullYear() * 100 + dateIn.getUTCMonth()
            const dtFinYYYYMM = dateFin.getUTCFullYear() * 100 + dateFin.getUTCMonth()

            return yyyymm >= dtInYYYYMM && yyyymm <= dtFinYYYYMM
          })

  // YYYY-Month - 2024-Jan
  const labelMapper =
    years.length === 1
      ? (ym: number[]) => `${months[ym[1] - 1]}`
      : (ym: number[]) => `${ym[0]}-${months[ym[1] - 1]}`
  const plotMonthsLabels = plotMonths.map(labelMapper)

  return {
    isLoading: dailyIsLoading || monthlyIsLoading,
    daily,
    dailyParks,
    dailyTurbs,
    monthly,
    monthlyParks,
    monthlyTurbs,
    dailyData: dailyIsLoading ? DEFAULT_DATA.dailyDefault : dailyData ?? DEFAULT_DATA.dailyDefault,
    monthlyData: monthlyIsLoading
      ? DEFAULT_DATA.monthlyDefault
      : monthlyData ?? DEFAULT_DATA.monthlyDefault,
    years,
    plotMonths,
    plotMonthsLabels,
  }
}
export default useData
