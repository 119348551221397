import { Marker, MarkerClusterer } from '@react-google-maps/api'
import { useQuery } from '@tanstack/react-query'
import { range } from 'd3-array'
import { useCallback, useState } from 'react'
import { IExibicao } from '.'
import MarkerAviso from '../../assets/img/MarkerAviso.png'
import { CivilOcorrencia, OccurrencesAPI, Site } from '../../client'
import criticidadesNames, { ICriticidade, ICriticidadeNivel } from '../../utils/criticidadeNames'
import { getOcorrenciasQueryKey } from '../ocorrencias/constants'
import { ModalOcorrencias } from './ModalOcorrencias'

const iconSize = 30
const clustererStyles = range(0, 99).map(() => {
  return { url: MarkerAviso, height: iconSize, width: iconSize }
})

type MarkerExtended = google.maps.MarkerOptions & {
  isAdded?: boolean
}

const iconFactory = (
  marker: string,
  iconSize: number,
  criticidade: ICriticidade,
  ocorrencia: CivilOcorrencia
) => {
  return {
    criticidade,
    ocorrencia,
    url: marker,
    size: new google.maps.Size(iconSize, iconSize),
    scaledSize: new google.maps.Size(iconSize, iconSize),
  }
}

const defaultQueryOptions = {
  refetchOnWindowFocus: true,
  retry: false,
  staleTime: 1000 * 2,
}

interface ClusterIconInfo {
  text: string
  index: number
  title?: string
  html?: string
}

const calculatorCallback = (
  markers: MarkerExtended[],
  setTitlesMarkers: React.Dispatch<React.SetStateAction<string>>
) => {
  const tempMarkersTitles = markers.map(m => m?.title)
  setTitlesMarkers(`${tempMarkersTitles.join('\n')}`)

  const clusterInfo: ClusterIconInfo = { text: String(markers.length), index: 0 }

  return clusterInfo
}

interface IOcorrenciasProps {
  id: string
  site: Site
  selectedCriticidade: string
  exibicao: IExibicao
}

const Ocorrencias = (props: IOcorrenciasProps) => {
  const { site, selectedCriticidade, exibicao } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedOcorrencia, setSelectedOcorrencia] = useState<CivilOcorrencia | null>()

  const { isLoading, data: ocorrencias } = useQuery<CivilOcorrencia[]>({
    queryKey: getOcorrenciasQueryKey(site?.site_id),
    queryFn: () => OccurrencesAPI.getAll({ siteId: site?.site_id }),
    enabled: !!site?.site_id,
    ...defaultQueryOptions,
  })

  const [titlesMarkers, setTitlesMarkers] = useState('')

  const MarkerCallback = useCallback(() => {
    const _selectedCrit = selectedCriticidade

    const _ocorrencias: CivilOcorrencia[] | undefined = ocorrencias?.filter(
      o => o?.criticidade === _selectedCrit || _selectedCrit === '0'
    )

    return (
      <MarkerClusterer
        calculator={(markers: MarkerExtended[]) => calculatorCallback(markers, setTitlesMarkers)}
        averageCenter
        styles={clustererStyles}
        title={isLoading ? titlesMarkers : ''} // Titulo desabilitado até correção
        gridSize={30}
        maxZoom={16}
      >
        {clusterer => (
          <>
            {_ocorrencias?.map(o => {
              if (!o.criticidade || !o.lat || !o.lon || !o.descricao_ocorrencia) {
                return null
              }
              const crit = criticidadesNames[o.criticidade as unknown as ICriticidadeNivel]
              return (
                <Marker
                  key={o.id}
                  title={`${o.item_id} - ${o.descricao_ocorrencia}`}
                  position={{ lat: o.lat, lng: o.lon }}
                  icon={crit?.icon ? iconFactory(crit.icon, 25, crit, o) : ''}
                  clusterer={clusterer}
                  onClick={() => onClick(o.id)}
                />
              )
            })}
          </>
        )}
      </MarkerClusterer>
    )
  }, [site, ocorrencias, selectedCriticidade, exibicao])

  if (isLoading || exibicao.toLowerCase() !== 'ocorrencias') {
    return <></>
  }

  const onClick = (ocorrenciaId?: string | null) => {
    const _ocorrencia: CivilOcorrencia | undefined = ocorrencias?.find(
      (o: CivilOcorrencia) => o.id === String(ocorrenciaId)
    )

    if (_ocorrencia === undefined) {
      return
    }

    setSelectedOcorrencia(_ocorrencia)
    setIsModalOpen(true)
  }

  return (
    <>
      <MarkerCallback />

      <ModalOcorrencias
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        ocorrencia={selectedOcorrencia}
      />
    </>
  )
}

export default Ocorrencias
