import {
  Grid,
  GridItem,
  Panel,
  PanelMain,
  PanelMainBody,
  Tab,
  Tabs,
  TabTitleText,
} from '@patternfly/react-core'
import { useState } from 'react'
import PanelSickHeader from '../../components/PanelStickyHeader'
import { categories, PLOT_TEMPORAL_MONTHLY } from './constants'
import PlotDaily from './PlotDaily'
import PlotDailyTotal from './PlotDailyTotal'
import PlotMonthly from './PlotMonthly'
import PlotMonthlyTotal from './PlotMonthlyTotal'
import PlotToDate from './PlotToDate'
import PlotToDateTotal from './PlotToDateTotal'
import useData from './useData'

const PanelSite = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
  categorySelection,
  activeType,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
  activeType: 'Total' | 'Por Categoria'
}) => {
  const {
    isLoading: dataIsLoading,
    daily,
    dailyParks,
    dailyTurbs,
    monthly,
    monthlyParks,
    monthlyTurbs,
    plotMonths,
    plotMonthsLabels,
  } = useData(dateIn, dateFin)

  return (
    <Panel className='pf-v5-u-box-shadow-sm'>
      <PanelMain>
        <PanelSickHeader title='Disponibilidade Temporal Por Site' />
        <PanelMainBody>
          <Grid hasGutter>
            <GridItem lg={8}>
              {activeType === 'Total' ? (
                <PlotMonthlyTotal
                  categorySelection={categorySelection}
                  isLoading={isLoading}
                  dataIsLoading={dataIsLoading}
                  revision={revision}
                  data={daily}
                  plotMonths={plotMonths}
                  plotMonthsLabels={plotMonthsLabels}
                  divId={PLOT_TEMPORAL_MONTHLY}
                />
              ) : (
                <PlotMonthly
                  isLoading={isLoading}
                  dataIsLoading={dataIsLoading}
                  revision={revision}
                  data={daily}
                  plotMonths={plotMonths}
                  plotMonthsLabels={plotMonthsLabels}
                  divId={PLOT_TEMPORAL_MONTHLY}
                />
              )}
            </GridItem>
            <GridItem lg={4}>
              {activeType === 'Total' ? (
                <PlotToDateTotal
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotToDate
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                />
              )}
            </GridItem>
            <GridItem>
              {activeType === 'Total' ? (
                <PlotDailyTotal
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                  categorySelection={categorySelection}
                />
              ) : (
                <PlotDaily
                  isLoading={isLoading}
                  dateIn={dateIn}
                  dateFin={dateFin}
                  revision={revision}
                />
              )}
            </GridItem>
          </Grid>
        </PanelMainBody>
      </PanelMain>
    </Panel>
  )
}

export default PanelSite
