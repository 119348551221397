import { Grid, GridItem } from '@patternfly/react-core'
import { useContext, useEffect, useState } from 'react'

import DatetimeRangePicker from '../../components/calendar/DatetimeRangePicker'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import DownloadButton from '../shared/DownloadButton'
import SelectListWtGs from '../shared/selects/DeprecatedSelectListWtGs'
import SelectSite from '../shared/selects/SelectSite'
import AlarmTabs from './AlarmTabs'
import ChartAlarmsHours from './ChartAlarmsHours'
import ChartAlarmsTurbines from './ChartAlarmsTurbines'
import TableAlarmsHours from './TableAlarmsHours'
import TableAlarmsTurbines from './TableAlarmsTurbines'

import { useQuery } from '@tanstack/react-query'
import PageFrame from '../shared/Page'
import Empty from './Empty'

import { AlarmHours, AlarmsAPI, AlarmTurbs } from '../../client'
import calcVerticalHeight from '../../utils/calcVerticalHeight'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const styleTable = {
  height: calcVerticalHeight({ gridSize: 1, elementSize: 0.5, customOffset: '4rem' }),
}

const formatDate = (date: Date) => date.toISOString().split('T')[0]

const keysAlarms = { turbine: 'alarmsTurbinesData', hours: 'alarmsHoursData' }
const onKeyAlarms = (
  type: keyof typeof keysAlarms,
  {
    siteId,
    turbId,
    tsIn,
    tsFin,
  }: {
    siteId: number
    turbId: number
    tsIn: Date
    tsFin: Date
  }
) => [keysAlarms[type], siteId, tsIn, tsFin, turbId]

const prefetch = async (
  siteId: number,
  turb_id: number,
  tsIn: Date,
  tsFin: Date,
  queryOptions: {
    enabled: boolean
    refetchOnWindowFocus: boolean
    retry: boolean
    staleTime: number
  }
) => {
  const paramsQuery = {
    siteId,
    tsIn: formatDate(tsIn).toString(),
    tsFin: formatDate(tsFin).toString(),
    turbId: turb_id,
  }
  const paramsKey = { siteId, turbId: turb_id, tsIn, tsFin }

  await queryClient.prefetchQuery({
    queryKey: onKeyAlarms('turbine', paramsKey),
    queryFn: () => AlarmsAPI.getTurbsSummary(paramsQuery),
    ...queryOptions,
  })

  await queryClient.prefetchQuery({
    queryKey: onKeyAlarms('hours', paramsKey),
    queryFn: () => AlarmsAPI.getHoursSummary(paramsQuery),
    ...queryOptions,
  })
}

const PageAlarms = () => {
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)
  const [wtGsSelected, setWtGsSelected] = useState({ turb_id: 0 })

  const dayInMilliseconds = 24 * 60 * 60 * 1000
  const today = new Date()
  const actualMonth = today.getMonth()
  const actualYear = today.getFullYear()

  const yesterday = new Date(today.getTime() - dayInMilliseconds)
  const yesterdayYear = yesterday.getFullYear()
  const yesterdayMonth = yesterday.getMonth()

  const { site, sites, turbs, isLoading: sitesIsLoading } = useContext(SitesContext)
  const [tsIn, setTsIn] = useState(new Date(actualYear, actualMonth - 1, today.getDate()))
  const [tsFin, setTsFin] = useState(new Date(yesterdayYear, yesterdayMonth, yesterday.getDate()))
  const [alarmsHoursData, setAlarmsHoursData] = useState<AlarmHours[]>([])
  const [alarmsTurbinesData, setAlarmsTurbinesData] = useState<AlarmTurbs[]>([])
  const [firstLoading, setFirstLoading] = useState(true)

  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }
  const paramsQuery = {
    siteId: site?.site_id,
    tsIn: formatDate(tsIn).toString(),
    tsFin: formatDate(tsFin).toString(),
    turbId: wtGsSelected.turb_id,
  }
  const paramsKey = { siteId: site?.site_id, turbId: wtGsSelected.turb_id, tsIn, tsFin }

  const { isLoading: alarmsTurbsIsLoading, data: _alarmsTurbinesData } = useQuery({
    queryKey: onKeyAlarms('turbine', paramsKey),
    queryFn: () => AlarmsAPI.getTurbsSummary(paramsQuery),
    ...queryOptions,
  })

  const { isLoading: alarmsHoursIsLoading, data: _alarmsHoursData } = useQuery({
    queryKey: onKeyAlarms('hours', paramsKey),
    queryFn: () => AlarmsAPI.getHoursSummary(paramsQuery),
    ...queryOptions,
  })

  useEffect(() => {
    if (alarmsTurbsIsLoading || alarmsHoursIsLoading || sitesIsLoading) return

    setAlarmsHoursData(_alarmsHoursData || [])
    setAlarmsTurbinesData(_alarmsTurbinesData || [])
  }, [
    alarmsTurbsIsLoading,
    alarmsHoursIsLoading,
    sitesIsLoading,
    site?.site_id,
    wtGsSelected?.turb_id,
  ])

  useEffect(() => {
    if (alarmsHoursData.length === 0) return
    setFirstLoading(false)
  }, [alarmsHoursData])

  const isLoading = firstLoading || sitesIsLoading

  const isContentLoading =
    (alarmsTurbsIsLoading || alarmsHoursIsLoading) &&
    alarmsHoursData.length > 0 &&
    alarmsTurbinesData.length > 0

  useEffect(() => {
    if (isLoading || !site?.site_id) return
    sites
      .filter(s => s.site_id !== site.site_id)
      .forEach(s => prefetch(s.site_id, wtGsSelected.turb_id, tsIn, tsFin, queryOptions))
  }, [isLoading])

  return (
    <PageFrame
      pageName='Alarmes'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      isContentLoading={isContentLoading}
      filters={[
        <SelectSite key='f1' enableUrlParams overwriteQueryParams />,
        <SelectListWtGs
          key={`SelectListWtGs_${site?.site_id}`}
          defaultsToComplex
          wtGsSelected={wtGsSelected}
          setWtGsSelected={setWtGsSelected}
        />,
        <DatetimeRangePicker
          key='DateSelect'
          value={{ startDate: tsIn, endDate: tsFin }}
          onChange={(newStart: Date, newEnd: Date) => {
            setTsIn(newStart)
            setTsFin(newEnd)
          }}
          calendarVariant='date'
          maxEnabled={yesterday}
        />,
        <DownloadButton
          key={`dl_btn_${site?.site_name}`}
          label='Exportar'
          url={`/sites/${site?.site_id}/alarms/report?${new URLSearchParams({
            ts_in: formatDate(tsIn),
            ts_fin: formatDate(tsFin),
          })}`}
          filename={`Alarms_${site?.site_name}.xlsx`}
        />,
      ]}
    >
      <Grid hasGutter>
        <GridItem className='pf-v5-u-my-md' md={6} sm={12}>
          <ChartAlarmsHours
            chartData={alarmsHoursData}
            revision={(isNavOpen as unknown as number) + 0}
            isLoading={isLoading}
          />
          <TableAlarmsHours
            dataTable={alarmsHoursData}
            style={styleTable}
            EmptyComponent={<Empty />}
          />
        </GridItem>
        <GridItem className='pf-v5-u-my-md' md={6} sm={12}>
          <ChartAlarmsTurbines
            chartData={alarmsTurbinesData}
            revision={(isNavOpen as unknown as number) + 0}
            turbs={turbs}
            isLoading={isLoading}
          />
          <TableAlarmsTurbines
            dataTable={alarmsTurbinesData}
            turbs={turbs}
            style={styleTable}
            EmptyComponent={<Empty />}
          />
        </GridItem>
      </Grid>
      <GridItem className='pf-v5-u-my-sm' sm={12}>
        {!!site?.site_id && (
          <AlarmTabs
            site_id={site.site_id}
            tsIn={formatDate(tsIn)}
            tsFin={formatDate(tsFin)}
            turb_id={wtGsSelected.turb_id}
            isNavOpen={isNavOpen}
          />
        )}
      </GridItem>
    </PageFrame>
  )
}

export default PageAlarms
