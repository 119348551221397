import { Grid, GridItem, Panel, PanelMain, PanelMainBody } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { AvailabilityAPI, Site } from '../../client'
import DatetimeMonthPicker from '../../components/calendar/DatetimeMonthPicker'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import DownloadButton from '../shared/DownloadButton'
import PageFrame from '../shared/Page'
import SelectSite from '../shared/selects/SelectSite'
import ChartAvailableEnergyMonthly from './ChartAvailableEnergyMonthly'
import ChartAvailableEnergyParks from './ChartAvailableEnergyParks'
import ChartAvailableWindTurbinesMonthly from './ChartAvailableWindTurbinesMonthly'

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
}

const onKeyAvailableEnergyMonthly = (siteId: number) => ['availableEnergyMonthly', siteId]
const onKeyAvailableEnergyParks = (siteId: number, farmSelected: number) => [
  'availableEnergyParks',
  siteId,
  farmSelected,
]
const onKeyAvailableWindTurbinesMonthly = (siteId: number) => [
  'availableWindTurbinesMonthly',
  siteId,
]

const prefetch = async (site: Site, farmSelected: number) => {
  await queryClient.prefetchQuery({
    queryKey: onKeyAvailableEnergyMonthly(site.site_id),
    queryFn: () => AvailabilityAPI.getMonthly({ siteId: site.site_id }),
    ...defaultQueryOptions,
  })

  await queryClient.prefetchQuery({
    queryKey: onKeyAvailableEnergyParks(site.site_id, farmSelected),
    queryFn: () =>
      AvailabilityAPI.getAvailabilityMonthlyParks({
        siteId: site.site_id,
        wfId: farmSelected,
      }),
    ...defaultQueryOptions,
  })

  await queryClient.prefetchQuery({
    queryKey: onKeyAvailableWindTurbinesMonthly(site.site_id),
    queryFn: () => AvailabilityAPI.getMonthlyTurbs({ siteId: site.site_id }),
    ...defaultQueryOptions,
  })
}

const EnergyAvailability = () => {
  const { site, sites, turbs, isLoading: sitesIsLoading, windFarms } = useContext(SitesContext)
  const farmSelected = 0

  const minDate = new Date(site.commissioning_date)
  const dtNow = new Date()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isNavOpen, setNavOpen] = useState(window.innerHeight < window.innerWidth)

  const queryOptions = { ...defaultQueryOptions, enabled: !!site?.site_id }

  const { isLoading: isDataMonthlyLoading, data: dataMonthly } = useQuery({
    queryKey: onKeyAvailableEnergyMonthly(site.site_id),
    queryFn: async () => {
      const result = await AvailabilityAPI.getMonthly({ siteId: site.site_id })
      return result.sort((a, b) => a.mm - b.mm)
    },
    ...queryOptions,
  })

  const { isLoading: isDataParksLoading, data: dataParks } = useQuery({
    queryKey: onKeyAvailableEnergyParks(site.site_id, farmSelected),
    queryFn: async () => {
      const result = await AvailabilityAPI.getAvailabilityMonthlyParks({
        siteId: site.site_id,
        wfId: farmSelected,
      })
      return result.sort((a, b) => a.mm - b.mm)
    },
    ...queryOptions,
  })

  const { isLoading: isTurbineMonthlyLoading, data: dataTurbinesMonthly } = useQuery({
    queryKey: onKeyAvailableWindTurbinesMonthly(site.site_id),
    queryFn: async () => {
      const result = await AvailabilityAPI.getMonthlyTurbs({ siteId: site.site_id })
      return result.sort((a, b) => a.mm - b.mm)
    },
    ...queryOptions,
  })

  const isLoading =
    isDataMonthlyLoading || isDataParksLoading || isTurbineMonthlyLoading || sitesIsLoading

  sites?.filter(s => s.site_id !== site.site_id).forEach(site => prefetch(site, farmSelected))

  return (
    <PageFrame
      pageName='Disponibilidade Energética'
      siteName={site?.site_name}
      siteId={site?.site_id}
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={isLoading}
      filters={[
        <SelectSite key='f1' enableUrlParams overwriteQueryParams />,
        <DatetimeMonthPicker
          key='DatetimeMonthPicker'
          date={selectedDate}
          maxEnabled={dtNow}
          minEnabled={minDate}
          onChange={setSelectedDate}
        />,

        <DownloadButton
          key={`dl_btn_${site?.site_name}`}
          label='Exportar'
          url={`/sites/${site.site_id}/availability/report?${new URLSearchParams({
            mm: String(selectedDate.getMonth() + 1),
            yyyy: String(selectedDate.getFullYear()),
          })}`}
          filename={`report_availability_${site.site_name}_${selectedDate.getFullYear()}_${
            selectedDate.getMonth() + 1
          }.xlsx`}
        />,
      ]}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Grid hasGutter>
              <GridItem lg={4} md={12}>
                {!isLoading && (
                  <ChartAvailableEnergyMonthly
                    dataChart={dataMonthly || []}
                    yearSelected={selectedDate.getFullYear()}
                    monthSelected={selectedDate.getMonth() + 1}
                    isLoading={isLoading}
                    revision={(isNavOpen as unknown as number) + 0}
                  />
                )}
                {!isLoading && (
                  <ChartAvailableEnergyParks
                    dataChart={dataParks || []}
                    windFarms={windFarms}
                    yearSelected={selectedDate.getFullYear()}
                    monthSelected={selectedDate.getMonth() + 1}
                    isLoading={isLoading}
                    revision={(isNavOpen as unknown as number) + 0}
                  />
                )}
              </GridItem>
              <GridItem lg={8} md={12}>
                {!isLoading && (
                  <ChartAvailableWindTurbinesMonthly
                    dataChart={dataTurbinesMonthly || []}
                    yearSelected={selectedDate.getFullYear()}
                    monthSelected={selectedDate.getMonth() + 1}
                    turbs={turbs}
                    isLoading={isLoading}
                    revision={(isNavOpen as unknown as number) + 0}
                  />
                )}
              </GridItem>
            </Grid>
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </PageFrame>
  )
}

export default EnergyAvailability
