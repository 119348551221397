import {
  Grid,
  GridItem,
  Icon,
  Panel,
  PanelMain,
  PanelMainBody,
  Text,
  TextVariants,
} from '@patternfly/react-core'
import { ExternalLinkAltIcon } from '@patternfly/react-icons'
import {
  InnerScrollContainer,
  OuterScrollContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@patternfly/react-table'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dataLossIcon from '../../assets/img/data-loss.svg'
import greenIcon from '../../assets/img/green.svg'
import orangeIcon from '../../assets/img/orange.svg'
import redIcon from '../../assets/img/red.svg'
import repairIcon from '../../assets/img/repair.png'
import yellowIcon from '../../assets/img/yellow.svg'
import { TowerIdentifier, TowersAPI } from '../../client'
import useVertical from '../../hooks/useVertical'
import { queryClient } from '../../services/api'
import ColorScale from '../../utils/colorScale'
import PageFrame from '../shared/Page'
import ModalHeatmap from './ModalHeatmap'
import TimeSeriesPlot from './TimeSeriesPlot'

interface IFlagIcons {
  [key: number]: string
}

export interface ITowersName {
  atual: string | undefined
  before: string | undefined
  after: string | undefined
}

export interface IDataItem {
  data: string
  event: string
  value: number
}

const flagIcons: IFlagIcons = {
  [-2]: dataLossIcon,
  [-1]: repairIcon,
  0: greenIcon,
  1: yellowIcon,
  2: orangeIcon,
  3: redIcon,
}

const TowersTable = ({
  towers,
  towerId,
  setTowerId,
  setModalOpen,
  towerIds,
}: {
  towers: TowerIdentifier[]
  towerId: number
  setTowerId: (value: number) => void
  setModalOpen: (value: boolean) => void
  towerIds: number[]
}) => {
  const navigate = useNavigate()

  const location = window.location.pathname
  const isPresentation = location.indexOf('apresentacao') !== -1

  let index = 0
  useEffect(() => {
    if (isPresentation) {
      const intervalId = setInterval(() => {
        index = index === towerIds.length - 1 ? 0 : ++index

        setTowerId(towerIds[index])
      }, 10 * 1000)

      return () => clearInterval(intervalId)
    }
  }, [])

  if (!towerId) return null

  const handleClick = (id: number, isHeatmap: boolean) => {
    setTowerId(id)
    if (isHeatmap) setModalOpen(true)
  }

  return (
    <OuterScrollContainer>
      <InnerScrollContainer>
        <Table
          aria-label='TowerTable'
          isStickyHeader
          variant='compact'
          borders={false}
          className='compact-table'
          id='TowerTable'
        >
          <Thead>
            <Tr>
              <Th className='pf-m-wrap'>Nome</Th>
              <Th className='pf-m-wrap'>H[m]</Th>
              <Th className='pf-m-wrap'>WS[m/s]</Th>
              <Th className='pf-m-wrap'>T[ºC]</Th>
              <Th className='pf-m-wrap'>RH[%]</Th>
              <Th className='pf-m-wrap'>P[mb]</Th>
              <Th className='pf-m-wrap'>Dados Até</Th>
              <Th className='pf-m-wrap'>Dias sem</Th>
              <Th className='pf-m-wrap'>Cobertura</Th>
              <Th className='pf-m-wrap'>G</Th>
              <Th className='pf-m-wrap'>S</Th>
              <Th className='pf-m-wrap'>T</Th>
              <Th className='pf-m-wrap'> </Th>
            </Tr>
          </Thead>

          <Tbody>
            {towers.map(t => (
              <Tr
                onRowClick={() => handleClick(t.tower_id, false)}
                isRowSelected={towerId === t.tower_id}
                isClickable
                key={t.tower_id}
              >
                <Td modifier='nowrap' dataLabel='Nome'>
                  {t.tower_name}
                </Td>
                <Td modifier='nowrap' dataLabel='h[m]'>
                  {t.height}
                </Td>
                <Td modifier='nowrap' dataLabel='WS[m/s]'>
                  {t.wind_speed}
                </Td>
                <Td modifier='nowrap' dataLabel='T[ºC]'>
                  {t.temperature}
                </Td>
                <Td modifier='nowrap' dataLabel='RH[%]'>
                  {t.relative_humidity}
                </Td>
                <Td modifier='nowrap' dataLabel='P[mb]'>
                  {Math.round(t.pressure)}
                </Td>
                <Td modifier='nowrap' dataLabel='Dados Até'>
                  {new Intl.DateTimeFormat('pt-BR').format(new Date(t.tower_dataset_end))}
                </Td>
                <Td modifier='nowrap' dataLabel='Dia sem'>
                  {t.dias_sem}
                </Td>
                <Td modifier='nowrap' dataLabel='Cobertura'>
                  {t.cobertura}
                </Td>
                <Td
                  modifier='nowrap'
                  dataLabel='Grave'
                  onClick={() => handleClick(t.tower_id, true)}
                >
                  <img src={flagIcons[t.hard_issues]} alt='Flag Icon' className='flag-icon' />
                </Td>
                <Td
                  modifier='nowrap'
                  dataLabel='Soft'
                  onClick={() => handleClick(t.tower_id, true)}
                >
                  <img src={flagIcons[t.soft_issues]} alt='Flag Icon' className='flag-icon' />
                </Td>
                <Td
                  modifier='nowrap'
                  dataLabel='Timestamp'
                  onClick={() => handleClick(t.tower_id, true)}
                >
                  <img src={flagIcons[t.ts_issues]} alt='Flag Icon' className='flag-icon' />
                </Td>
                <Td modifier='nowrap' dataLabel='Serie Temporal'>
                  <div
                    onClick={() => {
                      navigate(`/torres-anemometricas/${t.tower_id}`)
                    }}
                  >
                    <Icon title='Ir para serie temporal' color={ColorScale.cinza_medio}>
                      <ExternalLinkAltIcon />
                    </Icon>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </InnerScrollContainer>
    </OuterScrollContainer>
  )
}

const fetchMeasurements = async (towerId?: number) => {
  if (!towerId) return
  const r = await TowersAPI.getMeasurements({ towerId })
  return r.data
}
const fetchHeatmap = async (towerId?: number) => {
  if (!towerId) return
  return TowersAPI.getHeatmap({ towerId })
}

const TorresAnemometricas = () => {
  const isVertical = useVertical()
  const [isNavOpen, setNavOpen] = useState(!isVertical)
  const [isModalOpen, setModalOpen] = useState(false)
  const [towerId, setTowerId] = useState<number>()

  const getQueryOptions = (tId: number | undefined) => {
    return {
      enabled: !!tId,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 2,
    }
  }
  const prefetchMeasurements = async (tId?: number) => {
    if (!tId) return
    await queryClient.prefetchQuery(
      ['measurements', tId],
      async () => fetchMeasurements(tId),
      getQueryOptions(tId)
    )
  }
  const prefetchHeatmap = async (tId: number) => {
    if (!tId) return
    await queryClient.prefetchQuery(
      ['heatmap', tId],
      async () => fetchHeatmap(tId),
      getQueryOptions(tId)
    )
  }
  const handleModalToggle = () => {
    setModalOpen(!isModalOpen)
  }

  const { isLoading: towersIsLoading, data: towers } = useQuery(
    ['towers'],
    () => TowersAPI.getAll().then(r => r.sort((a, b) => a.tower_id - b.tower_id)),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    }
  )

  useEffect(() => {
    if (!towersIsLoading && towers?.length) setTowerId(towers[0].tower_id)
  }, [towersIsLoading])

  const { data: towerData, isLoading: isTowerDataLoading } = useQuery(
    ['measurements', towerId],
    () => fetchMeasurements(towerId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 2,
      enabled: !!towerId,
    }
  )

  const { isLoading: heatmapIsLoading, data: heatmap } = useQuery(
    ['heatmap', towerId],
    () => fetchHeatmap(towerId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 2,
      enabled: !!towerId,
    }
  )

  let towerIds: number[] = []
  let index = 0
  let beforeIndex = 0
  let afterIndex = 0

  if (towers && towers.length) {
    towerIds = towers.map(tower => tower.tower_id)
    index = towerId ? towerIds.indexOf(towerId) : 0
    beforeIndex = index === 0 ? towerIds.length - 1 : index - 1
    afterIndex = index === towerIds.length - 1 ? 0 : index + 1
  }

  prefetchMeasurements(towerIds[beforeIndex])
  prefetchHeatmap(towerIds[beforeIndex])
  prefetchMeasurements(towerIds[afterIndex])
  prefetchHeatmap(towerIds[afterIndex])

  const towersName: ITowersName = {
    atual: towers?.find(t => t.tower_id === towerId)?.tower_name,
    before: towers?.find(t => t.tower_id === towerIds[beforeIndex])?.tower_name,
    after: towers?.find(t => t.tower_id === towerIds[afterIndex])?.tower_name,
  }

  const props = {
    towers: towers && towers.length ? towers : [],
    towerId: towerId ?? 0,
    setTowerId,
    setModalOpen,
    towerIds,
  }

  return (
    <PageFrame
      pageName='Torres Anemométricas'
      isNavOpen={isNavOpen}
      setNavOpen={setNavOpen}
      isLoading={towersIsLoading && isTowerDataLoading}
    >
      <Panel className='pf-v5-u-box-shadow-sm'>
        <PanelMain>
          <PanelMainBody>
            <Grid hasGutter>
              <GridItem lg={12} xl={isNavOpen ? 7 : 6}>
                <TowersTable {...props} />
              </GridItem>
              <GridItem
                className='pf-v5-u-text-align-center'
                lg={12}
                xl={isNavOpen ? 5 : 6}
                id='containerTimeSeriesPlot'
              >
                <Text component={TextVariants.h2} id='titleTower'>
                  {towersName.atual}
                </Text>
                <TimeSeriesPlot towerData={towerData} revision={Number(isNavOpen)} />
              </GridItem>
            </Grid>

            <ModalHeatmap
              isModalOpen={isModalOpen}
              handleModalToggle={handleModalToggle}
              towersName={towersName}
              heatmapIsLoading={heatmapIsLoading}
              heatmap={heatmap?.data}
              beforeIndex={beforeIndex}
              afterIndex={afterIndex}
              {...props}
            />
          </PanelMainBody>
        </PanelMain>
      </Panel>
    </PageFrame>
  )
}

export default TorresAnemometricas
