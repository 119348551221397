import { CounterDataTemporalMonthly } from '../../client'
import COLORS from '../../utils/colorScale'
import { defaultAxis, defaultConfig } from '../../utils/plotly'

import Plot from '../shared/Plot'
import {
  COUNTERDATA_TYPES,
  COUNTERDATA_TYPE_LIST,
  PLOT_STYLE,
  PLOT_TEMPORAL_YEAR_TO_DATE,
} from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  paper_bgcolor: COLORS.branco,
  plot_bgcolor: COLORS.branco_clear,
  showlegend: true,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis2: { overlaying: 'x' },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}
const config = { ...defaultConfig, displayModeBar: false }

const calcYearlyTotals = (year: number, monthly: CounterDataTemporalMonthly[]) => {
  // Function is designed to compute the total and category-specific sums of data for a given year
  // from an array of monthly data entries
  let total = 0
  const categorySum = Object.fromEntries(COUNTERDATA_TYPE_LIST.map(cat => [cat, 0]))

  monthly.forEach(md => {
    if (new Date(md.ts).getFullYear() !== year) return

    COUNTERDATA_TYPE_LIST.forEach(d => {
      categorySum[d] = categorySum[d] + Number(md[d])
      total = total + Number(md[d])
    })
  })

  return [year, { total, categorySum }]
}

const PlotToDate = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
}) => {
  const { isLoading: dataIsLoading, years, monthly, daily } = useData(dateIn, dateFin)

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_YEAR_TO_DATE}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={config}
          style={PLOT_STYLE}
          onLegendClick={() => false}
        />
      </div>
    )
  }

  const _defaultAxis = { ...defaultAxis, type: 'bar', hoverinfo: 'all' }

  const yearlyTotals = Object.fromEntries(years.map(y => calcYearlyTotals(y, monthly)))

  let periodTotal = 0
  const periodSum = Object.fromEntries(COUNTERDATA_TYPE_LIST.map(d => [d, 0]))

  daily.forEach(md => {
    COUNTERDATA_TYPE_LIST.forEach(d => {
      periodSum[d] = periodSum[d] + Number(md[d])
      periodTotal = periodTotal + Number(md[d])
    })
  })

  const yearlyTraces = years.flatMap(yyyy => {
    const totals = yearlyTotals[yyyy]

    return COUNTERDATA_TYPE_LIST.map(cat => {
      return {
        ..._defaultAxis,
        y: [(totals.categorySum[cat] / totals.total) * 100],
        x: [yyyy],
        name: COUNTERDATA_TYPES[cat].description,
        marker: { color: COUNTERDATA_TYPES[cat].color },
        showlegend: yyyy === years[0],
      }
    })
  })

  const periodTraces = COUNTERDATA_TYPE_LIST.map(cat => {
    return {
      ..._defaultAxis,
      y: [(periodSum[cat] / periodTotal) * 100],
      x: ['Período'],
      name: COUNTERDATA_TYPES[cat].description,
      marker: { color: COUNTERDATA_TYPES[cat].color },
      showlegend: false,
    }
  })

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_YEAR_TO_DATE}
        useResizeHandler
        key={revision}
        data={[...periodTraces, ...yearlyTraces]}
        layout={PLOT_LAYOUT}
        config={config}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotToDate
