import { useContext, useState } from 'react'
import { DowntimeAero, DowntimeAPI, DowntimeBop, DowntimeONS } from '../../client'
import { ActionState } from '../../components/actionState'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import SitesContext from '../../contexts/SitesContext'
import { queryClient } from '../../services/api'
import { CACHE_KEY_BOP, CACHE_KEY_ONS, CACHE_KEY_WTG } from './constants'
import ModalAero from './ModalAero'
import ModalBOP from './ModalBOP'
import ModalRestricao from './ModalRestricao'
import { IEvent } from './TableAero'
import useData, { keyFromDate } from './useData'

const ModalTimeline = ({
  isOpen,
  onClose,
  dataToUpdate,
  selectedDate,
}: {
  isOpen: boolean
  onClose: () => void
  dataToUpdate: DowntimeBop | DowntimeAero | DowntimeONS | null
  selectedDate: Date
}) => {
  const { turbs, site } = useContext(SitesContext)
  const { tipos, componentes } = useData(selectedDate)
  const [actionState, setActionState] = useState<ActionState>('idle')
  const [isModalWarningOpen, setModalWarningOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<string[]>([])
  const [dataToDeleteRestricao, setDataToDeleteRestricao] = useState<number[]>([])

  const deleteDowntimeAero = async (downtimeIds: string[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        downtimeIds.map(async data => {
          await DowntimeAPI.deleteDowntimeAero({ siteId: site.site_id, downtimeId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    await queryClient.invalidateQueries([CACHE_KEY_WTG, site.site_id, keyFromDate(selectedDate, 1)])
    onClose()
    setModalWarningOpen(false)
    setActionState('idle')
  }

  const deleteDowntimeBop = async (downtimeIds: string[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        downtimeIds.map(async data => {
          await DowntimeAPI.deleteDowntimeBop({ siteId: site.site_id, downtimeId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    await queryClient.invalidateQueries([CACHE_KEY_BOP, site.site_id, keyFromDate(selectedDate, 1)])
    onClose()
    setModalWarningOpen(false)
    setActionState('idle')
  }

  const deleteDowntimeOns = async (restricaoId: number[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        restricaoId.map(async data => {
          await DowntimeAPI.deleteDowntimeOns({ siteId: site.site_id, restricaoId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    await queryClient.invalidateQueries([CACHE_KEY_ONS, site.site_id, keyFromDate(selectedDate, 1)])
    onClose()
    setModalWarningOpen(false)
    setActionState('idle')
  }

  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setModalWarningOpen(!isModalWarningOpen)
  }

  const handleDeleteClick = (item: string) => {
    setDataToDelete([item])
    handleModalConfirmationWarningToggle()
  }
  const handleDeleteClickRestricao = (item: number) => {
    setDataToDeleteRestricao([item])
    handleModalConfirmationWarningToggle()
  }

  const createEventSelected = (data: DowntimeAero): IEvent => {
    const turbName = turbs.find(e => e.turb_id === data.turb_id)?.name
    const tipoCategoria = tipos.find(t => t.id === data.tipo_id)?.categoria
    const component = componentes.find(c => c.id === data.component_id)?.component
    return {
      id: data.id ?? '',
      aero: turbName ?? '',
      inicio: data.ts_in ?? '',
      fim: data.ts_fin ?? '',
      categoria: tipoCategoria ?? '',
      componente: component ?? '',
      usuario: data.username ?? '',
      ...data,
    }
  }

  if (dataToUpdate === null) return null
  if (Object.hasOwn(dataToUpdate, 'turbs')) {
    return (
      <ModalBOP
        isOpen={isOpen}
        onClose={onClose}
        dataToUpdate={dataToUpdate as DowntimeBop}
        selectedDate={selectedDate}
        handleDeleteClick={handleDeleteClick}
        modalConfirmation={
          <ModalConfirmationWarning<string>
            isOpen={isModalWarningOpen}
            handleModalToggle={handleModalConfirmationWarningToggle}
            handleSubmit={id => deleteDowntimeBop([id])}
            title='Remover parada do Aerogerador'
            element={dataToDelete[0]}
            actionState={actionState}
          />
        }
      />
    )
  } else if (Object.hasOwn(dataToUpdate, 'component_id')) {
    const data = dataToUpdate as DowntimeAero

    return (
      <>
        <ModalAero
          isModalOpen={isOpen}
          selectedDate={selectedDate}
          handleModalToggle={onClose}
          handleDeleteClick={handleDeleteClick}
          eventSelected={createEventSelected(data)}
          modalConfirmation={
            <ModalConfirmationWarning<string>
              isOpen={isModalWarningOpen}
              handleModalToggle={handleModalConfirmationWarningToggle}
              handleSubmit={id => deleteDowntimeAero([id])}
              title='Remover parada do Aerogerador'
              element={dataToDelete[0]}
              actionState={actionState}
            />
          }
        />
      </>
    )
  } else if (Object.hasOwn(dataToUpdate, 'potencia_solicitada')) {
    return (
      <ModalRestricao
        isOpen={isOpen}
        onClose={onClose}
        dataToUpdate={dataToUpdate as DowntimeONS}
        selectedDate={selectedDate}
        handleDeleteClick={handleDeleteClickRestricao}
        modalConfirmation={
          <ModalConfirmationWarning<number>
            title='Remover restrição ONS'
            isOpen={isModalWarningOpen}
            handleModalToggle={handleModalConfirmationWarningToggle}
            handleSubmit={id => deleteDowntimeOns([id])}
            element={dataToDeleteRestricao[0]}
            actionState={actionState}
          />
        }
      />
    )
  } else {
    console.error('ModalTimeline: dataToUpdate is not a valid type')
    return null
  }
}

export default ModalTimeline
