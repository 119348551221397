import COLORS from '../../utils/colorScale'
import months from '../../utils/months'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'

import { A7ProductionMonthly } from '../../client'

const config = {
  ...defaultConfig,
  displayModeBar: false,
}
const layout = {
  template: layoutTemplate,
  barmode: 'group',
  margin: { b: 30, t: 5, l: 50, r: 50 },
  yaxis: {
    title: 'Produção mensal [GWh]',
    side: 'left',
    linewidth: 2,
    gridwidth: 2,
    mirror: 'ticks',
    fixedrange: true,
  },
  yaxis2: {
    title: 'Produção acumulada [GWh]',
    side: 'right',
    gridcolor: COLORS.transparent_bg,
    linewidth: 2,
    gridwidth: 2,
    mirror: 'ticks',
    fixedrange: true,
    overlaying: 'y',
  },
  xaxis: { linewidth: 2, gridwidth: 2, mirror: 'ticks', fixedrange: true },
  paper_bgcolor: COLORS.transparent_bg,
  plot_bgcolor: COLORS.transparent_bg,
}

const filterMonthly = (dailyData: A7ProductionMonthly[], year: number) => {
  return dailyData
    .filter((d: A7ProductionMonthly) => d.yyyy === year)
    .sort((a: A7ProductionMonthly, b: A7ProductionMonthly) => a.mm - b.mm)
}

const ChartProductionMonthly = ({
  monthlyData,
  year,
  month,
  revision,
  plotStyle,
  isLoading,
}: {
  monthlyData: A7ProductionMonthly[]
  year: number
  month: number
  revision: number
  plotStyle: any
  isLoading: boolean
}) => {
  if (isLoading) return null

  const dtNow = new Date()
  const yyyymm = dtNow.getFullYear() * 100 + dtNow.getMonth()

  const data = [
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      yaxis: 'y2',
      name: 'Real Ac.',
      type: 'scatter',
      mode: 'lines+markers',
      line: { width: 5 },
      marker: {
        color: COLORS.verde_cadmium,
        size: 15,
        line: {
          color: 'white',
          width: 1,
        },
      },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'P50 Ac',
      type: 'scatter',
      yaxis: 'y2',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.azul_escuro },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      yaxis: 'y2',
      name: 'P50 Proj Ac',
      type: 'scatter',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.azul_escuro },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'P90 Proj Ac',
      type: 'scatter',
      yaxis: 'y2',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.laranja_international },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'PPA Ac',
      yaxis: 'y2',
      type: 'scatter',
      line: { width: 5 },
      marker: { color: COLORS.roxo },
      hoverinfo: 'all',
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'BOP Restrição ONS',
      type: 'bar',
      marker: { color: COLORS.laranja_international },
      hoverinfo: 'all',
      offsetgroup: 1,
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'BOP Parada',
      type: 'bar',
      marker: { color: COLORS.amarelo_pantone },
      hoverinfo: 'all',
      offsetgroup: 1,
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'Turbine Parada',
      type: 'bar',
      marker: { color: COLORS.roxo_indigo },
      hoverinfo: 'all',
      offsetgroup: 1,
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'Windness',
      type: 'bar',
      marker: {
        pattern: {
          shape: '/',
          bgcolor: COLORS.cinza_medio_claro,
          solidity: 0.4,
        },
        color: 'white',
      },
      hoverinfo: 'all',
      offsetgroup: 1,
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'Real Mensal',
      type: 'bar',
      marker: { color: COLORS.verde },
      hoverinfo: 'all',
      offsetgroup: 1,
    },
    {
      ...defaultAxis,
      x: months,
      y: Array<number | undefined>(),
      base: Array<number | undefined>(),
      name: 'P50 Mensal',
      type: 'bar',
      line: { dash: 'dot', width: 5 },
      marker: { color: COLORS.azul_escuro },
      hoverinfo: 'all',
      offsetgroup: 2,
    },
  ]

  const monthly = filterMonthly(monthlyData, year)
  months.forEach((_, i) => {
    const e = monthly[i]
    if (!e) return

    const actual = e.actual ? e.actual / 1000 : 0
    const p50 = e.p50 ? e.p50 / 1000 : 0
    const actual_ac = e.actual_ac ? e.actual_ac / 1000 : 0
    const p50_ac = e.p50_ac ? e.p50_ac / 1000 : 0
    const p50_proj_ac = e.p50_proj_ac ? e.p50_proj_ac / 1000 : 0
    const p90_proj_ac = e.p90_proj_ac ? e.p90_proj_ac / 1000 : 0
    const ppa_ac = e.ppa_ac ? e.ppa_ac / 1000 : 0
    const bop_parada = e.bop_parada ? e.bop_parada / 1000 : 0
    const bop_restric = e.bop_restric ? e.bop_restric / 1000 : 0
    const turbine_parada = e.turbine_parada ? e.turbine_parada / 1000 : 0

    let windness = p50 - (actual + bop_parada + bop_restric + turbine_parada)
    windness = windness > 0 ? windness : 0
    windness = actual < p50 ? windness : 0
    windness = e.actual ? windness : 0
    // Prior to 2025 there are no available data for windness
    windness = year >= 2025 ? windness : 0

    data[0].y.push(i >= month ? undefined : actual_ac)
    data[1].y.push(p50_ac)
    data[2].y.push(i + 2 <= month ? undefined : p50_proj_ac)
    data[3].y.push(i + 2 <= month ? undefined : p90_proj_ac)
    data[4].y.push(ppa_ac)

    data[5].y.push(bop_restric)
    data[5].base.push(actual)

    data[6].y.push(bop_parada)
    data[6].base.push(actual + bop_restric)

    data[7].y.push(turbine_parada)
    data[7].base.push(actual + bop_restric + bop_parada)

    const _yyyymm = year * 100 + i

    data[8].y.push(_yyyymm >= yyyymm ? undefined : windness)
    data[8].base.push(actual + bop_restric + bop_parada + turbine_parada)

    data[9].y.push(actual)
    data[10].y.push(p50)
  })
  const style = { ...plotStyle }

  return (
    <div className='pf-v5-u-p-sm'>
      <Plot
        divId='ChartProductionMonthly'
        useResizeHandler
        data={data}
        layout={layout}
        config={config}
        style={style}
        key={revision}
      />
    </div>
  )
}

export default ChartProductionMonthly
