import {
  Button,
  Divider,
  EmptyStateIcon,
  Flex,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  Tooltip,
} from '@patternfly/react-core'
import { CogIcon, TimesIcon } from '@patternfly/react-icons'
import { Eraser } from 'lucide-react'
import { useContext, useEffect, useMemo, useState } from 'react'
import {
  DowntimeAero,
  DowntimeAPI,
  DowntimeComponentes,
  DowntimeTipos,
  Turbines,
} from '../../client'
import { ActionState } from '../../components/actionState'
import EntityTable from '../../components/EntityTable'
import ModalConfirmationWarning from '../../components/ModalConfirmationWarning'
import Select from '../../components/selects/Select'
import SitesContext from '../../contexts/SitesContext'
import useMobile from '../../hooks/useMobile'
import { queryClient } from '../../services/api'
import calcVerticalHeight from '../../utils/calcVerticalHeight'
import { formatDateTimeBrl, formatDateWithoutTime } from '../../utils/formatDate'
import { getFirstSegment } from '../../utils/getFirstSegment'
import {
  CACHE_KEY_WTG,
  GRID_SIZE,
  Responsabilidade,
  RESPONSABILIDADES,
  TABLE_HEADER_HEIGHT,
  TAB_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
} from './constants'
import ModalAero from './ModalAero'
import ModalConsolidar from './ModalConsolidar'
import ResizeButtons from './ResizeButtons'
import { keyFromDate } from './useData'

type DowntimeUser = {
  id: string
  username: string
}

export interface IEvent extends DowntimeAero {
  aero: string
  categoria: string
  usuario: string
  inicio: string
  fim: string
  componente: string
}

type DowntimeAeroCustom = DowntimeAero & {
  aero: string
  categoria: string
  componente: string
  responsabilidade: string
}

const DEFAULT_SEARCH_INPUT: Turbines = {
  circuit: '',
  commissioning_date: '',
  hh: 0,
  name: 'WTG',
  rated_pwr: 0,
  rotor_d: 0,
  site_id: 0,
  trafo: '',
  turb_class: '',
  turb_id: 0,
  turb_model: '',
  utmzone: '',
  wf_id: 0,
  x: 0,
  y: 0,
  y_pos: 0,
  cov_model: null,
  gbx_model: null,
  gen_model: null,
  lat: null,
  lon: null,
  pop: null,
  wind_sector_management: null,
}

const DEFAULT_SEARCH_CATEGORY: DowntimeTipos = {
  id: 0,
  categoria: 'Categoria',
  tipo: 'Categoria',
  description: null,
}
const DEFAULT_SEARCH_COMPONENT: DowntimeComponentes = {
  id: 0,
  component: 'Componente',
}
const DEFAULT_SEARCH_RESP: Responsabilidade = { id: false, nome: 'Responsabilidade' }
const DEFAULT_SEARCH_USER: DowntimeUser = { id: '', username: 'Usuário' }

export const formatAlarmDate = (date: string) => {
  const _dt = new Date(date)
  _dt.setMilliseconds(0)
  return formatDateTimeBrl(_dt)
}

const TableAero = ({
  selectedDate,
  downtimeAero,
  componentes,
  tipos,
  resizeMode,
  setResizeMode,
}: {
  selectedDate: Date
  downtimeAero: DowntimeAero[]
  componentes: DowntimeComponentes[]
  tipos: DowntimeTipos[]
  resizeMode: number
  setResizeMode: (m: number) => void
}) => {
  const { turbs, site } = useContext(SitesContext)

  const [searchInputAero, setSearchInputAero] = useState<Turbines>(DEFAULT_SEARCH_INPUT)
  const [searchInputCategory, setSearchInputCategory] =
    useState<DowntimeTipos>(DEFAULT_SEARCH_CATEGORY)
  const [searchInputComponent, setSearchInputComponent] =
    useState<DowntimeComponentes>(DEFAULT_SEARCH_COMPONENT)
  const [searchInputResponsibility, setSearchInputResponsibility] =
    useState<Responsabilidade>(DEFAULT_SEARCH_RESP)
  const [searchInputUser, setSearchInputUser] = useState<DowntimeUser>(DEFAULT_SEARCH_USER)
  const [isModalWarningOpen, setModalWarningOpen] = useState(false)
  const [isModalConfirmSelectedDatasOpen, setModalConfirmSelectedDatasOpen] = useState(false)
  const [isModalConsolidarOpen, setModalConsolidarOpen] = useState(false)
  const [dataToDelete, setDataToDelete] = useState<string[]>([])

  const [checkboxSelected, setCheckboxSelected] = useState<DowntimeAeroCustom[]>([])

  const [actionState, setActionState] = useState<ActionState>('idle')
  const [activeFilter, setActiveFilter] = useState(true)

  const [isModalOpen, setModalOpen] = useState(false)
  const [eventSelected, setEventSelected] = useState<DowntimeAero>()

  const handleOpenModalEnergyExpected = (event: DowntimeAero) => {
    setEventSelected(event)
    setModalOpen(true)
  }

  const tsIn = useMemo(
    () => formatDateWithoutTime(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)),
    [selectedDate]
  )
  const tsFin = useMemo(
    () =>
      formatDateWithoutTime(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)),
    [selectedDate]
  )

  const deleteDowntimeAero = async (downtimeIds: string[]) => {
    setActionState('pending')
    try {
      await Promise.all(
        downtimeIds.map(async data => {
          await DowntimeAPI.deleteDowntimeAero({ siteId: site.site_id, downtimeId: data })
        })
      )
    } catch (error) {
      console.log(error)
      setActionState('failure')
      return
    }
    setActionState('success')
    setModalOpen(false)
    setCheckboxSelected([])
    await queryClient.invalidateQueries([CACHE_KEY_WTG, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const handleClickCreateModal = () => {
    setEventSelected(undefined)
    setModalOpen(true)
  }

  const handleClickModalConsolidar = () => {
    setModalConsolidarOpen(true)
  }

  const handleModalConfirmationWarningToggle = () => {
    setActionState('idle')
    setModalWarningOpen(!isModalWarningOpen)
  }

  const handleModalConsolidarToggle = () => {
    setActionState('idle')
    setModalConsolidarOpen(!isModalConsolidarOpen)
  }

  const handleDeleteClick = (item: string | null) => {
    if (item) {
      setDataToDelete([item])
      handleModalConfirmationWarningToggle()
    }
  }

  const handleDeleteManyClick = (item: string[]) => {
    setDataToDelete(item)
    handleModalConfirmSelectedDatasToggle()
  }

  const handleModalConfirmSelectedDatasToggle = () => {
    setActionState('idle')
    setModalConfirmSelectedDatasOpen(!isModalConfirmSelectedDatasOpen)
  }

  function getName(id: number | null) {
    const found = turbs.find(a => a.turb_id === id)
    return found ? found.name : ''
  }

  function getCategory(id: number | null) {
    const found = tipos.find(t => t.id === id)
    return found ? `${found.categoria}` : ''
  }

  function getComponent(id: number | null) {
    const found = componentes.find(c => c.id === id)
    return found ? found.component : ''
  }

  function getResponsabilidade(id: boolean | null) {
    const found = RESPONSABILIDADES.find(item => item.id === id)
    return found ? found.nome : ''
  }

  const isFilterable =
    !!searchInputAero.turb_id ||
    !!searchInputCategory.id ||
    !!searchInputComponent.id ||
    !!searchInputResponsibility.id ||
    !!searchInputUser.id

  const aeroDowntimeData = useMemo(() => {
    if (!downtimeAero) return []
    setCheckboxSelected([])

    const _data: DowntimeAeroCustom[] = downtimeAero
      .map(
        el =>
          ({
            aero: getName(el.turb_id),
            componente: getComponent(el.component_id),
            categoria: getCategory(el.tipo_id),
            responsabilidade: getResponsabilidade(el.responsabilidade_re),
            ...el,
          } as DowntimeAeroCustom)
      )
      .filter(
        el =>
          el.aero.toLowerCase().includes(getName(searchInputAero.turb_id).toLowerCase()) &&
          (searchInputCategory.id !== 0
            ? el.categoria
                ?.toLowerCase()
                .includes((searchInputCategory.categoria as string).toLowerCase())
            : true) &&
          (searchInputComponent.id !== 0
            ? el.componente.toLowerCase().includes(searchInputComponent.component.toLowerCase())
            : true) &&
          (searchInputResponsibility.nome !== 'Responsabilidade'
            ? el.responsabilidade_re === searchInputResponsibility.id
            : true) &&
          (searchInputUser.username !== 'Usuário' ? el.username === searchInputUser.username : true)
      )

    return _data
  }, [
    downtimeAero,
    componentes,
    tipos,
    searchInputAero,
    searchInputCategory,
    searchInputComponent,
    searchInputResponsibility,
    searchInputUser,
  ])

  const handleConsolidar = async (data: DowntimeAero[]) => {
    setActionState('pending')
    const isInvalid = data.some(el => data[0].turb_id !== el.turb_id)
    const requestBody = data.map(d => d.id ?? '')

    try {
      if (isInvalid) throw Error('Aerogeradores diferentes')
      await DowntimeAPI.mergeDowntimeAero({ siteId: site.site_id, requestBody })
    } catch (error: any) {
      console.log(error.status)
      setActionState('failure')
      return
    }
    setActionState('success')

    queryClient.invalidateQueries([CACHE_KEY_WTG, site.site_id, keyFromDate(selectedDate, 1)])
  }

  const resetFilters = () => {
    setSearchInputAero(DEFAULT_SEARCH_INPUT)
    setSearchInputCategory(DEFAULT_SEARCH_CATEGORY)
    setSearchInputComponent(DEFAULT_SEARCH_COMPONENT)
    setSearchInputResponsibility(DEFAULT_SEARCH_RESP)
    setSearchInputUser(DEFAULT_SEARCH_USER)
  }

  useEffect(() => {
    if (
      searchInputAero.name === 'WTG' &&
      searchInputCategory.categoria === 'Categoria' &&
      searchInputComponent.component === 'Componente' &&
      searchInputResponsibility.nome === 'Responsabilidade' &&
      searchInputUser.username === 'Usuário'
    ) {
      setActiveFilter(true)
    } else {
      setActiveFilter(false)
    }
  }, [
    searchInputAero,
    searchInputCategory,
    searchInputComponent,
    searchInputResponsibility,
    searchInputUser,
  ])

  useEffect(() => {
    resetFilters()
  }, [tsIn, tsFin, site.site_id])

  const isMobile = useMobile()
  const [toggleFilters, setToggleFilters] = useState<boolean>(false)

  const filterGroupItems = (
    <>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<Turbines>
          items={[{ id: 0, name: 'WTG' }, ...turbs]}
          itemValueName={'name'}
          itemKeyName={'turb_id'}
          selected={searchInputAero}
          onChange={i => setSearchInputAero(i)}
          selectProps={{
            menuAppendTo: 'parent',
            maxHeight: '320px',
            width: `${isMobile ? '89vw' : '120px'}`,
          }}
          enableFilter
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<DowntimeTipos>
          items={[
            { id: 0, categoria: 'Categoria', tipo: 'Categoria' },
            ...aeroDowntimeData.filter((valor, index, self) => {
              return self.findIndex(element => element.categoria === valor.categoria) === index
            }),
          ]}
          itemValueName={'categoria'}
          itemKeyName={'id'}
          selected={searchInputCategory}
          onChange={i => setSearchInputCategory(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<DowntimeComponentes>
          items={[
            { id: 0, component: 'Componente' },
            ...componentes.filter(i => {
              const found = aeroDowntimeData.find(c => c.component_id === i.id)
              return found ? { id: found.id, component: found.componente } : null
            }),
          ]}
          itemValueName={'component'}
          itemKeyName={'id'}
          selected={searchInputComponent}
          onChange={i => setSearchInputComponent(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<Responsabilidade>
          items={[
            { id: true, nome: 'Responsabilidade' },
            { id: true, nome: 'Rio Energy' },
            { id: false, nome: 'Fabricante' },
          ]}
          itemValueName={'nome'}
          itemKeyName={'id'}
          selected={searchInputResponsibility}
          onChange={i => setSearchInputResponsibility(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem style={{ padding: 0 }}>
        <Select<DowntimeUser>
          items={[
            { id: '', username: 'Usuário', tipo: 'Usuário' },
            ...aeroDowntimeData.filter((valor, index, self) => {
              return self.findIndex(element => element.username === valor.username) === index
            }),
          ]}
          itemValueName='username'
          itemKeyName='id'
          selected={searchInputUser}
          onChange={i => setSearchInputUser(i)}
          selectProps={{ menuAppendTo: 'parent', ...(isMobile && { width: '89vw' }) }}
          className='pf-v5-u-p-none'
        />
      </ToolbarItem>
      <ToolbarItem
        style={{
          ...(isMobile
            ? { padding: '4px 0', paddingBottom: '6px' }
            : { padding: '4px 0.35rem 4px 0' }),
        }}
      >
        <Button
          variant='control'
          style={{
            ...(isMobile && { width: '89vw' }),
            display: 'flex',
            alignItems: 'center',
            gap: '2px',
          }}
          onClick={resetFilters}
          isDisabled={activeFilter}
        >
          <Eraser size={'16px'} />
          Limpar
        </Button>
      </ToolbarItem>
    </>
  )

  const buttonGroupItems = (
    <>
      <ToolbarItem
        style={{
          ...(isMobile ? { padding: '4px 0', paddingTop: '6px' } : { padding: '4px 0.35rem' }),
          margin: 0,
        }}
      >
        <Button
          onClick={handleClickCreateModal}
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '89vw' }),
          }}
        >
          Adicionar
        </Button>
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        {checkboxSelected.length < 2 || searchInputAero.name === 'WTG' ? (
          <Tooltip content='Utilize o filtro WTG e selecione ao menos 2 eventos'>
            <Flex>
              <Button
                style={{
                  height: '36px',
                  margin: 0,
                  ...(isMobile && { width: '89vw' }),
                }}
                onClick={handleClickModalConsolidar}
                isDisabled={checkboxSelected.length < 2 || searchInputAero.name === 'WTG'}
                variant='secondary'
              >
                Consolidar
              </Button>
            </Flex>
          </Tooltip>
        ) : (
          <Button
            style={{
              height: '36px',
              margin: 0,
              ...(isMobile && { width: '89vw' }),
            }}
            onClick={handleClickModalConsolidar}
            isDisabled={checkboxSelected.length < 2 || searchInputAero.name === 'WTG'}
            variant='secondary'
          >
            Consolidar
          </Button>
        )}
      </ToolbarItem>
      <ToolbarItem style={{ padding: `4px ${isMobile ? 0 : '0.35rem'}`, margin: 0 }}>
        <Button
          style={{
            height: '36px',
            margin: 0,
            ...(isMobile && { width: '89vw' }),
          }}
          variant='danger'
          onClick={() => handleDeleteManyClick(checkboxSelected.map(i => i.id as string))}
          isDisabled={!checkboxSelected[0]}
        >
          Remover
        </Button>
      </ToolbarItem>
      <Divider
        orientation={{
          default: isMobile ? 'horizontal' : 'vertical',
        }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />
      <ResizeButtons setResizeMode={setResizeMode} resizeMode={resizeMode} />
    </>
  )

  const toolbarItems = (
    <div
      style={{
        display: 'flex',
        ...(isMobile && { flexDirection: 'column' }),
        width: '100%',
      }}
    >
      <ToolbarGroup
        variant='filter-group'
        style={{
          margin: 0,
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
        }}
      >
        {filterGroupItems}
      </ToolbarGroup>

      <Divider
        orientation={{
          default: isMobile ? 'horizontal' : 'vertical',
        }}
        style={{
          ...(isMobile ? { width: '89vw' } : { height: '3vh', alignSelf: 'center' }),
          margin: 0,
        }}
      />

      <ToolbarGroup
        variant='button-group'
        style={{
          ...(isMobile && { display: 'flex', flexDirection: 'column' }),
          width: 'fit-content',
          margin: 0,
        }}
      >
        {buttonGroupItems}
      </ToolbarGroup>
    </div>
  )

  const tableAndContainerHeight = useMemo(() => {
    const h = calcVerticalHeight({
      elementSize: GRID_SIZE / 2 - resizeMode,
      gridSize: GRID_SIZE,
    })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TAB_HEADER_HEIGHT + TOOLBAR_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  const heightBottom = useMemo(() => {
    const h = calcVerticalHeight({ elementSize: GRID_SIZE / 2 - resizeMode, gridSize: GRID_SIZE })
    // Offset tem que ser fora, calcVerticalHeight distribui igualmente o offset entre os elementos
    const customOffset = TABLE_HEADER_HEIGHT
    const s = `calc(${h} - ${customOffset}rem)`

    return s
  }, [resizeMode])

  return (
    <div style={{ height: heightBottom }}>
      {isMobile && (
        <Flex
          style={{ justifyContent: 'flex-start', backgroundColor: 'white', position: 'relative' }}
        >
          <Button
            style={{
              backgroundColor: 'transparent',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              justifyContent: 'center',
              height: '35px',
              color: 'black',
            }}
            onClick={() => setToggleFilters(!toggleFilters)}
          >
            {!toggleFilters ? (
              <>
                Ações
                <EmptyStateIcon icon={CogIcon} />
              </>
            ) : (
              <EmptyStateIcon icon={TimesIcon} />
            )}
          </Button>
        </Flex>
      )}

      {!isMobile || toggleFilters ? (
        <Flex
          style={{
            backgroundColor: 'white',
            position: 'relative',
          }}
        >
          <Toolbar
            id='toolbar-group-types'
            style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}
          >
            <ToolbarContent style={{ width: '100%' }}>{toolbarItems}</ToolbarContent>
          </Toolbar>
        </Flex>
      ) : null}

      <EntityTable<DowntimeAeroCustom>
        items={aeroDowntimeData}
        itemKeyName='id'
        isCompact
        columnConfig={[
          { key: 'id', description: 'Id', formatter: getFirstSegment },
          {
            key: 'aero',
            description: 'WTG',
            onFilterClick: t => {
              setSearchInputAero(turbs.find(turb => getName(turb.turb_id) === t) as Turbines)
            },
          },
          { key: 'categoria', description: 'Categoria' },
          { key: 'username', description: 'Usuário' },
          { key: 'ts_in', description: 'Início', formatter: v => formatDateTimeBrl(new Date(v)) },
          { key: 'ts_fin', description: 'Fim', formatter: v => formatDateTimeBrl(new Date(v)) },
          { key: 'componente', description: 'Componente' },
          { key: 'responsabilidade', description: 'Responsabilidade' },
          { key: 'descricao', description: 'Descrição' },
        ]}
        onClickEdit={instance => {
          handleOpenModalEnergyExpected(instance)
        }}
        onClickDelete={instance => handleDeleteClick(instance.id as string)}
        style={{
          maxHeight: tableAndContainerHeight,
          minHeight: tableAndContainerHeight,
        }}
        selectedMode='checkbox'
        checkboxSelected={checkboxSelected}
        setCheckboxSelected={setCheckboxSelected}
        isFilterable={isFilterable}
        onClearFilters={resetFilters}
      />

      <ModalConsolidar<DowntimeAeroCustom[]>
        isOpen={isModalConsolidarOpen}
        handleModalToggle={handleModalConsolidarToggle}
        handleSubmit={handleConsolidar}
        title='Consolidar eventos'
        element={checkboxSelected}
        actionState={actionState}
        additionalInformation={
          checkboxSelected.length > 1 && (
            <EntityTable<DowntimeAeroCustom>
              items={checkboxSelected}
              itemKeyName='id'
              isCompact
              columnConfig={[
                { key: 'id', description: 'Id', formatter: getFirstSegment },
                { key: 'aero', description: 'WTG' },
                { key: 'categoria', description: 'Categoria' },
                { key: 'username', description: 'Usuário' },
                {
                  key: 'ts_in',
                  description: 'Início',
                  formatter: v => formatDateTimeBrl(new Date(v)),
                },
                {
                  key: 'ts_fin',
                  description: 'Fim',
                  formatter: v => formatDateTimeBrl(new Date(v)),
                },
                { key: 'componente', description: 'Componente' },
                { key: 'responsabilidade', description: 'Responsabilidade' },
                { key: 'descricao', description: 'Descrição' },
              ]}
            />
          )
        }
      />
      <ModalConfirmationWarning<string>
        isOpen={isModalWarningOpen}
        handleModalToggle={handleModalConfirmationWarningToggle}
        handleSubmit={id => deleteDowntimeAero([id])}
        title='Remover parada do Aerogerador'
        element={dataToDelete[0]}
        actionState={actionState}
      />
      <ModalConfirmationWarning<DowntimeAeroCustom[]>
        isOpen={isModalConfirmSelectedDatasOpen}
        handleModalToggle={handleModalConfirmSelectedDatasToggle}
        handleSubmit={data => deleteDowntimeAero(data.map(i => i.id as string))}
        title='Remover paradas dos Aerogeradores'
        element={checkboxSelected}
        actionState={actionState}
        alertMessage='Tem certeza que deseja remover estes itens? Essa ação não poderá ser desfeita.'
        additionalInformation={
          checkboxSelected.length > 0 && (
            <EntityTable<DowntimeAeroCustom>
              items={checkboxSelected}
              itemKeyName='id'
              isCompact
              columnConfig={[
                { key: 'id', description: 'Id', formatter: getFirstSegment },
                { key: 'aero', description: 'WTG' },
                { key: 'username', description: 'Usuário' },
                {
                  key: 'ts_in',
                  description: 'Início',
                  formatter: v => formatDateTimeBrl(new Date(v)),
                },
                {
                  key: 'ts_fin',
                  description: 'Fim',
                  formatter: v => formatDateTimeBrl(new Date(v)),
                },
              ]}
            />
          )
        }
      />

      <ModalAero
        isModalOpen={isModalOpen}
        handleModalToggle={() => setModalOpen(false)}
        handleDeleteClick={handleDeleteClick}
        eventSelected={eventSelected}
        selectedDate={selectedDate}
        modalConfirmation={
          <ModalConfirmationWarning<string>
            isOpen={isModalWarningOpen}
            handleModalToggle={handleModalConfirmationWarningToggle}
            handleSubmit={id => deleteDowntimeAero([id])}
            title='Remover parada do Aerogerador'
            element={dataToDelete[0]}
            actionState={actionState}
          />
        }
      />
    </div>
  )
}

export default TableAero
