import { Spinner } from '@patternfly/react-core'
import { InternMap } from 'd3-array'
import { CSSProperties, useContext } from 'react'
import { MastData, SignalTypes } from '../../../client'
import MastContext from '../../../contexts/MastContext'
import useMobile from '../../../hooks/useMobile'
import ColorScale, { plot_color_scale_200 } from '../../../utils/colorScale'
import { defaultAxis, defaultConfig, layoutTemplate } from '../../../utils/plotly'
import { sortByDateKey } from '../../../utils/sort'
import Plot from '../../shared/Plot'

const config = { ...defaultConfig }
const style: CSSProperties = {
  minHeight: '10rem',
  height: '100%',
}

const PlotSignal = ({
  tsIn,
  tsFin,
  signalType,
  mastDataBySensor,
  revision,
}: {
  tsIn: Date
  tsFin: Date
  signalType: SignalTypes
  mastDataBySensor: InternMap<number, MastData[]>
  revision: number
}) => {
  const { mast, sensors: allSensors } = useContext(MastContext)
  const sensors = allSensors.filter(s => signalType.id === s.signal_type_id)

  const layout = {
    template: layoutTemplate,
    margin: { b: 35, t: 35, l: 60, r: 15 },
    autorange: false,
    yaxis: {
      range: [signalType.min_graph, signalType.max_graph],
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
      title: { text: `${signalType.description} [${signalType.units}]`, font: { size: 11 } },
    },
    xaxis: {
      linewidth: 2,
      gridwidth: 2,
      mirror: 'ticks',
      zeroline: false,
    },
  }

  const data = sensors
    .flatMap((sensor, i) => {
      const _mastData = mastDataBySensor.get(sensor.id)
      if (!_mastData) {
        return null
      }
      const mastData = sortByDateKey<MastData>(_mastData, 'ts', 'asc')
      const x = mastData.map(d => d.ts)
      const avg: number[] = []
      const lowerBound: number | null = sensor.min_estimate
      const upperBound: number | null = sensor.max_estimate
      const avgEstimated: number | null = sensor.avg_estimate
      const sensorColor: string = plot_color_scale_200[i]

      mastData.forEach(md => {
        avg.push(md.avg)
      })

      if (lowerBound == null || upperBound == null || avgEstimated == null) {
        return [
          {
            x,
            y: avg,
            type: 'scatter',
            mode: 'line',
            name: `${sensor.label} - AVG`,
            marker: { color: sensorColor },
          },
        ]
      }

      return [
        {
          x,
          y: avg,
          type: 'scatter',
          mode: 'line',
          name: `${sensor.label} - AVG`,
          marker: { color: sensorColor },
        },
        {
          x: [tsIn, tsFin],
          y: [lowerBound, lowerBound],
          type: 'scatter',
          mode: 'lines',
          marker: { color: ColorScale.vermelho_puro },
          name: 'Min Estimated',
        },
        {
          x: [tsIn, tsFin],
          y: [upperBound, upperBound],
          type: 'scatter',
          mode: 'lines',
          marker: { color: ColorScale.vermelho_puro },
          name: 'Max Estimated',
        },
        {
          x: [tsIn, tsFin],
          y: [avgEstimated, avgEstimated],
          type: 'scatter',
          mode: 'lines',
          marker: { color: ColorScale.cinza_medio_claro },
          name: 'Avg Estimated',
        },
      ]
    })
    .filter(d => d)

  const isMobile = useMobile()
  if (data.length === 0) return <Spinner className='pf-v5-u-m-xl' size='xl' aria-label='Loading' />

  return (
    <div style={style}>
      <Plot
        divId={`PlotSignal${mast.name}${signalType.id}`}
        key={`${mast.name}-${signalType.id}-${revision}`}
        useResizeHandler
        data={data}
        layout={layout}
        config={{ ...config, ...(isMobile && { displayModeBar: false }) }}
        style={style}
        revision={revision}
      />
    </div>
  )
}

export default PlotSignal
