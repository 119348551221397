import { Text, TextContent } from '@patternfly/react-core'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'

import state1 from '../../../../assets/img/estado-1.png'
import state2 from '../../../../assets/img/estado-2.png'
import state3 from '../../../../assets/img/estado-3.png'
import state4 from '../../../../assets/img/estado-4.png'
import { AvailabilityAPI, Site } from '../../../../client'
import ColorScale from '../../../../utils/colorScale'

interface IAvailableInfos {
  siteId: number
  site: Site
}

const AvailableInfos = ({ siteId, site }: IAvailableInfos) => {
  const { data } = useQuery(
    ['avail_realtime', siteId],
    () => AvailabilityAPI.getRealtime({ siteId }),
    {
      refetchOnWindowFocus: true,
      retry: false,
      placeholderData: {
        ambiental_stop: null,
        no_communication: null,
        operational: null,
        stop: null,
        site_id: siteId,
        ts: null,
      },
      refetchInterval: 1000 * 15,
    }
  )

  const { operational, no_communication, stop, ambiental_stop } = data || {}
  const thumbStates = [state1, state2, state3, state4]

  return (
    <div className='pf-v5-u-display-flex pf-v5-u-justify-content-space-between'>
      <TextContent>
        <Text
          component='p'
          style={{ color: ColorScale.verde_cadmium }}
          className='txt-verde pf-v5-u-font-weight-bold'
        >
          Em operação: {operational}
        </Text>
        <Text
          component='p'
          style={{ color: ColorScale.cinza_medio }}
          className='txt-lilas pf-v5-u-font-weight-bold'
        >
          Sem comunicação: {no_communication}
        </Text>
        <Text
          component='p'
          style={{ color: ColorScale.laranja_chama }}
          className='txt-salmao pf-v5-u-font-weight-bold'
        >
          Parada: {stop}
        </Text>
        <Text
          component='p'
          style={{ color: ColorScale.azul_pantone }}
          className='txt-azul pf-v5-u-font-weight-bold'
        >
          Parada ambiental: {ambiental_stop}
        </Text>
      </TextContent>
      <Link to={`/mapa/${site.site.toLowerCase()}`}>
        <img
          style={{
            maxWidth: '100%',
            maxHeight: window.innerHeight < window.innerWidth ? '4rem' : '15rem',
          }}
          src={thumbStates[siteId - 1]}
          alt=''
        />
      </Link>
    </div>
  )
}

export default AvailableInfos
