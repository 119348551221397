import { sum } from 'd3-array'
import ColorScale from '../../utils/colorScale'
import { defaultAxis, defaultConfig } from '../../utils/plotly'

import { CounterDataTemporalMonthly } from '../../client'
import Plot from '../shared/Plot'
import { categories, PLOT_STYLE, PLOT_TEMPORAL_YEAR_TO_DATE } from './constants'
import useData from './useData'

const PLOT_LAYOUT = {
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: true,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis2: { overlaying: 'x' },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}
const config = { ...defaultConfig, displayModeBar: false }

const calcYearlyTotals = (
  year: number,
  monthly: CounterDataTemporalMonthly[],
  categorySelection: categories
) => {
  const yearlyTotals = { online: 0, downtime: 0 }

  monthly.forEach(mm => {
    if (new Date(mm.ts).getFullYear() !== year) return

    const downtime = sum(categorySelection.downtime.map(cat => mm[cat]))
    const online = sum(categorySelection.online.map(cat => mm[cat]))

    yearlyTotals.downtime = yearlyTotals.downtime + downtime
    yearlyTotals.online = yearlyTotals.downtime + online
  })

  return [year, yearlyTotals]
}

const PlotToDateTotal = ({
  isLoading,
  dateIn,
  dateFin,
  revision,
  categorySelection,
}: {
  isLoading: boolean
  dateIn: Date
  dateFin: Date
  revision: number
  categorySelection: categories
}) => {
  const { isLoading: dataIsLoading, years, monthly, daily } = useData(dateIn, dateFin)

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={PLOT_TEMPORAL_YEAR_TO_DATE}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={config}
          style={PLOT_STYLE}
          onLegendClick={() => false}
        />
      </div>
    )
  }

  const _defaultAxis = {
    ...defaultAxis,
    type: 'bar',
    hoverinfo: 'all',
    marker: {
      color: ColorScale.verde,
    },
  }

  const yearlyTotals = Object.fromEntries(
    years.map(y => calcYearlyTotals(y, monthly, categorySelection))
  )

  const periodTotals = { online: 0, downtime: 0 }

  daily.forEach(mm => {
    const downtime = sum(categorySelection.downtime.map(cat => mm[cat]))
    const online = sum(categorySelection.online.map(cat => mm[cat]))

    periodTotals.downtime = periodTotals.downtime + downtime
    periodTotals.online = periodTotals.downtime + online
  })

  const yearlyTraces = years.flatMap(yyyy => {
    const totals = yearlyTotals[yyyy]

    return {
      ..._defaultAxis,
      y: [(totals.online / (totals.online + totals.downtime)) * 100],
      x: [yyyy],
      name: 'Disponibilidade Temporal',
      showlegend: yyyy === years[0],
    }
  })

  const data = [
    {
      ..._defaultAxis,
      y: [(periodTotals.online / (periodTotals.online + periodTotals.downtime)) * 100],
      x: ['Período'],
      showlegend: false,
    },
    ...yearlyTraces,
  ]

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={PLOT_TEMPORAL_YEAR_TO_DATE}
        useResizeHandler
        key={revision}
        data={data}
        layout={PLOT_LAYOUT}
        config={config}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotToDateTotal
