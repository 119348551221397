import { sum } from 'd3-array'
import { useMemo } from 'react'
import ColorScale from '../../utils/colorScale'
import { toDateTuple, withoutTimeToDate } from '../../utils/formatDate'
import { defaultAxis, layoutTemplate } from '../../utils/plotly'
import Plot from '../shared/Plot'
import {
  COUNTERDATA_TYPES,
  COUNTERDATA_TYPE_LIST,
  DEFAULT_PLOT_CONFIG,
  PLOT_STYLE,
} from './constants'

import {
  CounterDataTemporalDaily,
  CounterDataTemporalDailyPark,
  CounterDataTemporalDailyTurb,
} from '../../client'

const PLOT_LAYOUT = {
  template: layoutTemplate,
  paper_bgcolor: ColorScale.branco,
  plot_bgcolor: ColorScale.branco_clear,
  showlegend: false,
  hovermode: 'x unified',
  autosize: true,
  barmode: 'stack',
  margin: { b: 40, t: 10, r: 10, l: 40 },
  xaxis: {
    ...defaultAxis,
    fixedrange: true,
    mirror: 'ticks',
    linewidth: 2,
    gridwidth: 2,
    title: 'Mês - Período selecionado',
    type: 'category',
  },
  yaxis: { ...defaultAxis, fixedrange: true, ticksuffix: '%', range: [0, 110] },
}

const formatCatYYYYMM = (cat: string, yyyy: number, mm: number) => `${cat}-${yyyy * 100 + mm}`

const PlotMonthly = ({
  isLoading,
  dataIsLoading,
  revision,
  data,
  plotMonths,
  plotMonthsLabels,
  divId,
}: {
  isLoading: boolean
  dataIsLoading: boolean
  revision: number
  data: CounterDataTemporalDaily[] | CounterDataTemporalDailyPark[] | CounterDataTemporalDailyTurb[]
  plotMonths: number[][]
  plotMonthsLabels: string[]
  divId: string
}) => {
  const _defaultAxis = { ...defaultAxis, x: plotMonthsLabels, type: 'bar', hoverinfo: 'all' }

  const monthlyTotals = new Map<number, number>()
  const monthlyCatTotals = new Map<string, number>()

  data.forEach(dd => {
    const [yyyy, _mm, _] = toDateTuple(withoutTimeToDate(dd.ts))
    const mm = _mm + 1

    const catTotal = monthlyTotals.get(yyyy * 100 + mm) ?? 0

    monthlyTotals.set(yyyy * 100 + mm, catTotal + sum(COUNTERDATA_TYPE_LIST.map(cat => dd[cat])))

    COUNTERDATA_TYPE_LIST.forEach(cat => {
      const catYYYYMM = formatCatYYYYMM(cat, yyyy, mm)
      const catTotal = monthlyCatTotals.get(catYYYYMM) ?? 0

      monthlyCatTotals.set(`${cat}-${yyyy * 100 + mm}`, catTotal + (dd[cat] ?? 0))
    })
  })

  const traces = useMemo(() => {
    if (isLoading || dataIsLoading) return []

    const bars = COUNTERDATA_TYPE_LIST.map(cat => {
      const yAxis = plotMonths.map(ym => {
        const [yyyy, mm] = ym

        const yValue = monthlyCatTotals.get(formatCatYYYYMM(cat, yyyy, mm))

        if (!yValue) return undefined
        const catTotal = monthlyTotals.get(yyyy * 100 + mm) ?? 1

        return Math.round(((yValue ?? 0) / catTotal) * 10000) / 100
      })

      return {
        ..._defaultAxis,
        y: yAxis,
        name: COUNTERDATA_TYPES[cat].description,
        marker: { color: COUNTERDATA_TYPES[cat].color },
      }
    })

    return bars
  }, [data, revision, isLoading, dataIsLoading])

  if (isLoading || dataIsLoading) {
    return (
      <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
        <Plot
          divId={divId}
          useResizeHandler
          key={revision}
          data={[]}
          layout={PLOT_LAYOUT}
          config={DEFAULT_PLOT_CONFIG}
          style={PLOT_STYLE}
        />
      </div>
    )
  }

  return (
    <div style={PLOT_STYLE} className='pf-v5-u-mt-0'>
      <Plot
        divId={divId}
        useResizeHandler
        key={revision}
        data={traces}
        layout={PLOT_LAYOUT}
        config={DEFAULT_PLOT_CONFIG}
        style={PLOT_STYLE}
        onLegendClick={() => false}
      />
    </div>
  )
}

export default PlotMonthly
